<template>
  <div class="group-background-hover">
    <div
      v-if="post"
      class="row thread-message group-hover group-background-hover-item editor-prototype q-px-md q-py-sm relative-position"
      :id="post.id + '-post'"
      :style="
        post.ispinpost && {
          'background-color': '#dbecff',
        }
      "
    >
      <q-menu
        context-menu
        v-if="!post.is_bot"
        content-class="overflow-auto"
        auto-close
      >
        <q-list>
          <q-item
            clickable
            @click="markMessageAsRead"
            v-if="
              hasMessageUnread && currentUser && currentUser.id !== post.user.id
            "
          >
            <q-item-section>
              <q-item-label>Mark read</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            v-else-if="currentUser && currentUser.id !== post.user.id"
            clickable
            @click="markMessageAsUnread"
          >
            <q-item-section>
              <q-item-label>Mark unread</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable @click="$copyContentToClip(post.content)">
            <q-item-section>
              <q-item-label>Copy text</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable @click="addMessagePin()" v-if="!post.ispinpost">
            <q-item-section>
              <q-item-label>Pin to top</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="deletePinMessageDialogHandler(true)"
            v-if="post.ispinpost"
          >
            <q-item-section>
              <q-item-label>Un-pin to top</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            @click="$emit('deletePostDialogHandler', post.id)"
            class="text-red"
            v-if="
              canRemoveMessageFromWorkspace ||
              isWorkspaceOwner ||
              (currentUser && currentUser.id === post.user.id)
            "
          >
            <q-item-section>
              <q-item-label>Delete message</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <!-- Thread Message -->
      <div class="row flex-no-wrap full-width">
        <div class="row items-start">
          <template v-if="post.is_bot">
            <q-avatar class="q-ml-md" size="35px">
              <img
                src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab-round.svg"
                alt="Heycollab"
                class="text-avatar-style"
              />
            </q-avatar>
          </template>
          <!-- Not bot -->
          <template v-else>
            <div style="margin-top: 12px">
              <div
                slot="activator"
                v-if="
                  (hasNotification || hasMessageUnread) &&
                  currentUser &&
                  currentUser.id !== post.user.id
                "
                class="cursor-pointer notification-sidebar-dot q-mr-sm pos-absolute"
                @click="markMessageAsRead"
              >
                <q-tooltip> Mark read </q-tooltip>
              </div>
              <div
                v-else-if="currentUser && currentUser.id !== post.user.id"
                slot="activator"
                class="cursor-pointer notification-sidebar-outline-dot q-mr-sm pos-absolute"
                @click="markMessageAsUnread"
              >
                <q-tooltip> Mark unread </q-tooltip>
              </div>
            </div>
            <q-avatar
              size="35px"
              color="blue-grey-11"
              text-color="grey-10"
              class="q-ml-md"
              @click="$emit('showUserInfo', post.user)"
            >
              <img
                :src="post.user.pic"
                :alt="post.user.first"
                v-if="post.user.pic"
              />
              <!-- <span class="text-bold text-subtitle1" v-else>
                {{ post.user.first | firstChar
                }}{{ post.user.last | firstChar }}
              </span> -->
              <avatar v-else :size="35" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="post.user.first+' '+post.user.last"></avatar>
            </q-avatar>
          </template>
        </div>
        <div class="thread-middle-section flex-1 q-pl-sm">
          <!-- Post User Detail -->
          <div class="messaging-user-detail-chip" v-if="post.is_bot">
            <span class="user-name text-black">Heycollab</span>
            <span class="time">
              <span>{{ post.created_at | gettime }}</span>
            </span>
          </div>

          <div class="messaging-user-detail-chip" v-else>
            <span class="user-name text-black">{{ post.user.first }}</span>
            <span class="time">
              <span v-if="post.updated_at > post.created_at">
                {{ post.created_at | gettime }}
                <span class="capitalize-text an-12">(Edited)</span>
              </span>
              <span v-else>{{ post.created_at | gettime }}</span>
            </span>
            <q-btn
              v-if="post.is_email_to_task"
              flat
              :icon="$icons.matEmail"
              size="11px"
              class="q-ml-xs"
              rounded
              dense
              style="color: #a1acb8"
            >
              <q-tooltip>Message sent from e-mail</q-tooltip>
            </q-btn>
            <q-btn
              v-if="post.user.userStatus"
              flat
              size="11px"
              class="q-ml-xs"
              rounded
              dense
              style="color: #a1acb8"
            >
            <img
              style="width: 20px;"
              :src="'/static/react-icons/' + post.user.userStatus.icon"
              />
              <q-tooltip>{{post.user.userStatus.name}}</q-tooltip>
            </q-btn>

          </div>
          <!-- Normal Message -->
          <div
            class="post-content mt-0 dont-break-out"
            v-if="post.content"
            v-html="PostHtml"
          ></div>
          <!-- Media Attachment -->
          <slot></slot>
          <!-- Forward Section -->
          <div
            v-if="post.forwardedPost"
            class="forword-post-layout q-pa-md q-mt-sm"
            style=""
          >
            <!-- Forwarded Post User section -->
            <div class="row">
              <div class="flex-1">
                <div class="messaging-user-detail-chip">
                  <span
                    @click="$emit('showUserInfo', post.forwardedPost.owner)"
                    class="user-name cursor-pointer"
                  >
                    {{ post.forwardedPost.owner.first }}
                  </span>
                  <span class="time">
                    <span class="capitalize-text">
                      {{ post.forwardedPost.created_at | forwardPostTime }}
                    </span>
                  </span>
                </div>
                <!-- Forwarded Post Content -->
                <div
                  class="post-content dont-break-out"
                  v-if="post.forwardedPost.content"
                  v-html="forwardPost"
                ></div>
                <slot name="forwardedmedia"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row flex-no-wrap justify-between items-center full-width">
        <!-- Reactions List badges -->
        <div
          style="margin-left: 60px"
          class="q-mt-sm"
          v-if="
            !Array.isArray(post.reactiondata) &&
            Object.keys(post.reactiondata).length !== 0 &&
            post.reactiondata.constructor === Object
          "
        >
          <div class="row inline">
            <template v-for="(reactiondata, index) in post.reactiondata">
              <div class="q-mr-sm" :key="index">
                <div class="relative-position reaction-icon row items-center">
                  <img
                    :src="`/static/react-icons/${index}.svg`"
                    :alt="index"
                    class="react-icon-size q-mr-xs cursor-pointer"
                    @click="$emit('reactOnPost', { post, reaction: index })"
                  />
                  <div class="text-black" style="font-size: 12px">
                    {{ reactiondata.length }}
                  </div>
                </div>
                <q-tooltip
                  :offset="[-50, 0]"
                  content-class="bg-white elevation-2 q-pa-md"
                >
                  <div class="row reaction-menu">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="q-mr-md react-menu-icon"
                    />
                    <q-list class="column justify-center" v-if="reactiondata">
                      <q-item
                        :style="
                          reactiondata.length === 1 && {
                            'margin-bottom': '0px',
                          }
                        "
                        class="q-pa-none q-mb-sm"
                        style="min-height: 30px"
                        v-for="item in reactiondata"
                        :key="item.id"
                      >
                        <div class="row items-center">
                          <div class="q-mr-sm">
                            <q-avatar
                              color="blue-grey-11"
                              text-color="grey-10"
                              size="26px"
                            >
                              <img
                                :src="item.pic"
                                :alt="item.first"
                                v-if="item.pic"
                              />
                              <!-- <span
                                class="text-avatar-style"
                                style="font-size: 9px"
                                v-else
                              >
                                {{ item.first | firstChar
                                }}{{ item.last | firstChar }}
                              </span> -->
                              <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                            </q-avatar>
                          </div>
                          <div class="reacted-user">
                            {{ item.first }} {{ item.last }}
                          </div>
                          <div v-if="item.time" class="reaction-time q-ml-xs">
                            {{ item.time | formatDateTime }}
                          </div>
                        </div>
                      </q-item>
                    </q-list>
                  </div>
                </q-tooltip>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="absolute group-hover-item" style="right: 0; bottom: 5px">
        <div class="row" v-if="seenList.length">
          <div class="cursor-pointer">
            <q-avatar
              class="q-mr-xs"
              size="20px"
              color="blue-grey-11"
              text-color="grey-10"
              v-for="(seenby, index) in seenDisplay"
              :key="`seen-${index}`"
            >
              <img
                :src="seenby.user.pic"
                :alt="seenby.user.first"
                v-if="seenby.user.pic"
              />
              <!-- <span style="font-size: 9px" v-else>
                {{ seenby.user.first | firstChar
                }}{{ seenby.user.last | firstChar }}
              </span> -->
              <avatar v-else :size="20" :customStyle="{'font-size':'9px', 'font-weight':'600'}" :username="seenby.user.first+' '+seenby.user.last"></avatar>
            </q-avatar>
            <q-avatar
              class="q-mr-xs"
              size="20px"
              color="blue-grey-11"
              text-color="grey-10"
              v-if="seenList.length > 2"
            >
              <span class="text-avatar-style an-10"
                >+{{ seenList.length - seenDisplay.length }}</span
              >
            </q-avatar>
            <q-menu content-class="overflow-auto seen-menu" :offset="[150, 0]">
              <q-list class="q-pa-md">
                <div
                  class="subheader an-14 text-bold black--text"
                  style="height: 25px"
                >
                  Read by:
                </div>
                <q-item
                  v-for="(seenby, index) in seenList"
                  v-bind:key="`seenby-list-${index}`"
                  @click.stop="findUserInCache(seenby.user.id)"
                  clickable
                  class="q-pa-none q-pa-xs"
                  v-close-popup
                >
                  <q-item-section
                    style="min-width: 45px"
                    class="q-pr-none"
                    avatar
                  >
                    <q-avatar
                      size="36px"
                      color="blue-grey-11"
                      text-color="grey-10"
                    >
                      <img
                        :src="seenby.user.pic"
                        :alt="seenby.user.first"
                        v-if="seenby.user.pic"
                      />
                      <!-- <span
                        class="text-avatar-style row justify-center align-center an-12"
                        v-else
                      >
                        {{ seenby.user.first | firstChar
                        }}{{ seenby.user.last | firstChar }}
                      </span> -->
                      <avatar v-else :size="36" :customStyle="{'font-size':'16px', 'font-weight':'600'}" :username="seenby.user.first+' '+seenby.user.last"></avatar>
                    </q-avatar>
                  </q-item-section>
                  <q-item-section style="max-width: 100px" class="ellipsis">
                    <q-item-label lines="1">
                      <span class="seen-by">
                        {{ seenby.user.first | capitalize }}
                      </span>
                      <span class="seen-at">
                        {{ seenby.seen_at | formatDateTime }}
                      </span>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>
      </div>
      <div class="absolute thread-icons group-hover-item" style="right: 10px">
        <div>
          <div class="row items-center" v-if="!post.is_bot">
            <q-btn
              flat
              :icon="$icons.matSentimentSatisfied"
              size="12px"
              class="thread-icon"
              rounded
              dense
            >
              <q-menu content-class="overflow-auto apply-width" auto-close>
                <div class="card react-emoji-card">
                  <img
                    v-for="(emoji, index) in reactions"
                    :key="index"
                    :src="'/static/react-icons/' + emoji.icon"
                    alt="ANGRY"
                    @click="
                      () => {
                        $emit('reactOnPost', { post, reaction: emoji }),
                          $mixpanelEvent('message-reaction', {});
                      }
                    "
                    class="react-emoji-icon q-mr-sm cursor-pointer"
                  />
                </div>
              </q-menu>
              <q-tooltip> Add Reaction </q-tooltip>
            </q-btn>
            <q-circular-progress
              indeterminate
              size="xs"
              :thickness="0.2"
              color="primary"
              class="q-ml-xs"
              v-if="selectedMessageId === post.id && taskMessageLoader"
            />
            <q-btn
              flat
              :icon="$icons.fasCheck"
              size="xs"
              class="thread-icon"
              rounded
              dense
              v-else-if="canPostMessageFromWorkspace && !post.is_task"
              @click="createTaskFromMessageHandler"
            >
              <q-tooltip> Create a task </q-tooltip>
            </q-btn>
            <q-btn
              flat
              :icon="$icons.fasShare"
              size="xs"
              class="thread-icon q-pa-xs"
              rounded
              dense
              @click="$emit('sharePost', post)"
              v-if="canPostMessageFromWorkspace || isWorkspaceOwner"
            >
              <q-tooltip> Share Message </q-tooltip>
            </q-btn>
            <q-btn
              flat
              :icon="$icons.matBorderColor"
              size="xs"
              rounded
              dense
              class="thread-icon q-pa-xs"
              @click="$emit('editPost', post)"
              v-if="
                (canPostMessageFromWorkspace || isWorkspaceOwner) &&
                currentUser &&
                currentUser.id === post.user.id
              "
            >
              <q-tooltip> Edit Message </q-tooltip>
            </q-btn>
            <q-btn
              flat
              :icon="$icons.matBookmark"
              size="sm"
              rounded
              color="pink"
              dense
              padding="5px"
              v-if="post.isbookmarked"
              @click="removeMessageBookmark(post)"
            >
              <q-tooltip> Remove from Saved items </q-tooltip>
            </q-btn>
            <q-btn
              flat
              :icon="$icons.matBookmarkBorder"
              size="sm"
              class="thread-icon"
              padding="5px"
              rounded
              color="black"
              dense
              v-if="!post.isbookmarked"
              @click="addMessageBookmark(post)"
            >
              <q-tooltip> Add to Saved items </q-tooltip>
            </q-btn>
          </div>
          <div
            class="thread-options group-hover-item row"
            v-if="isAdmin && post.is_bot"
          >
            <q-btn
              flat
              :icon="$icons.fasTrash"
              size="xs"
              color="blue-grey-5"
              class="q-pa-xs"
              rounded
              dense
              @click.native.stop="$emit('deletePostDialogHandler', post.id)"
            >
              <q-tooltip> Remove Message </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialog
      v-model="deleteConfirm.flag"
      title="Remove pinned message?"
      question="Are you sure you want to remove this pinned message?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => deletePinMessageDialogHandler(false)"
      :successMethod="deletePinMessage"
      :loading="false"
    />
  </div>
</template>
<script>
import { compile } from "@/components/Editor/MarkupUtils";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import Avatar from 'vue-avatar';

import {
  UpdateNotificationsMutation,
  UpdateReadCursorMutation,
  AddMessageBookmarkMutation,
  RemoveMessageBookmarkMutation,
  AddMessagePinMutation,
  RemoveMessagePinMutation,
} from "@/gql";
export default {
  name: "ThreadMessage",
  props: [
    "post",
    "reactions",
    "isAdmin",
    "isWorkspaceOwner",
    "currentUser",
    "canRemoveMessageFromWorkspace",
    "canPostMessageFromWorkspace",
    "className",
    "currentWorkspace",
    "createTaskFromMessage",
    "taskMessageLoader",
    "selectedMessageId",
    "currentCompany",
    "activeCompany",
    "labels",
  ],
  components: {
    ConfirmDialog,
    Avatar,
  },
  data() {
    return {
      showCreateTask: false,
      planeDescription: null,
      deleteConfirm: {
        flag: false,
      },
    };
  },
  mounted() {
    this.preloadImage();
  },
  computed: {
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    hasMessageUnread() {
      if (
        this.currentWorkspace.read_cursor !== 0 &&
        this.currentWorkspace.read_cursor <= this.post.id &&
        this.currentUser.id !== this.post.user.id
      ) {
        return true;
      }
      return false;
    },
    hasNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.object_id === this.post.id);
        return notifications.length > 0;
      }
      return false;
    },
    PostHtml() {
      return compile(this.post.content);
    },
    forwardPost() {
      return compile(this.post.forwardedPost.content);
    },
    seenList() {
      return this.post && this.currentUser
        ? this.post.seens.filter(
            (seen) =>
              seen.user &&
              seen.user.id !== this.currentUser.id &&
              seen.user.id !== this.post.user.id
          )
        : [];
    },
    seenDisplay() {
      return this.seenList !== undefined && this.seenList.length > 2
        ? this.seenList.slice(0, 2)
        : this.seenList;
    },
  },
  methods: {
    deletePinMessageDialogHandler(flag = true) {
      this.deleteConfirm.flag = flag;
    },
    async deletePinMessage() {
      try {
        const variables = {
          post_id: this.post.id,
        };

        const postData = this.$api.getEntity("post", this.post.id);
        postData.ispinpost = !postData.ispinpost;

        const PinMessagesQ = this.$api.getQuery(
          `PinMessagesQuery:${this.currentWorkspace.id}`
        );

        if (PinMessagesQ.data.pinMessages) {
          PinMessagesQ.data.pinMessages = PinMessagesQ.data.pinMessages.filter(
            (pinMessages) => pinMessages.id !== this.post.id
          );
        }

        await this.$api.mutate({
          mutation: RemoveMessagePinMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        throw new Error(error);
      }
      this.deletePinMessageDialogHandler(false);
    },
    async addMessageBookmark() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Save tasks, messages and files across workspaces in your private saved items tab.";
        this.showCreateTask = true;
      } else {
        try {
          const variables = {
            post_id: this.post.id,
          };
          const post = this.$api.getEntity("post", this.post.id);
          post.isbookmarked = !this.post.isbookmarked;
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.currentWorkspace.id
          );
          workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
          await this.$api.mutate({
            mutation: AddMessageBookmarkMutation,
            variables,
            skipUpdate: true,
          });
          this.$mixpanelEvent("save-message", {});
        } catch (error) {
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.currentWorkspace.id
          );
          workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
          throw new Error(error);
        }
      }
    },
    async addMessagePin() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Save tasks, messages and files across workspaces in your private saved items tab.";
        this.showCreateTask = true;
      } else {
        try {
          const variables = {
            post_id: this.post.id,
          };

          const PinMessagesQ = this.$api.getQuery(
            `PinMessagesQuery:${this.currentWorkspace.id}`
          );

          const post = this.$api.getEntity("post", this.post.id);
          post.ispinpost = !this.post.ispinpost;

          if (PinMessagesQ.data.pinMessages) {
            PinMessagesQ.data.pinMessages.push(post);
          }

          await this.$api.mutate({
            mutation: AddMessagePinMutation,
            variables,
            skipUpdate: true,
          });
          this.$mixpanelEvent("pin-to-top-message", {});
        } catch (error) {
          console.error("ERROR: ThreadMessage => addMessagePin", error);
        }
      }
    },
    async removeMessageBookmark() {
      try {
        const variables = {
          post_id: this.post.id,
        };

        const post = this.$api.getEntity("post", this.post.id);
        post.isbookmarked = !this.post.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveMessageBookmarkMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.currentWorkspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    closePlanExpiredDialog() {
      this.showCreateTask = false;
    },
    createTaskFromMessageHandler() {
      let eligible = this.$checkEligiblity("task_from_message");
      if (!eligible) {
        return false;
      } else if (
        this.companies &&
        this.companies[this.activeCompany].status.subscriptionOver
      ) {
        this.planeDescription =
          "Create tasks from messages with a simple click.";
        this.showCreateTask = true;
      } else {
        let postContentArr = this.post.content.split(" ");
        let mentionArray = [];
        postContentArr.forEach((item) => {
          if (item.includes("@")) {
            item = item.substring(1);
            mentionArray.push(item);
          }
        });
        const task = {
          title: "",
          html: null,
          details: this.post.content,
          labels: [],
          card_id: null,
        };
        this.createTaskFromMessage(
          task,
          this.post.media,
          this.post.id,
          mentionArray
        );
        this.$mixpanelEvent("task-from-message-click", {});
      }
    },
    preloadImage() {
      if (this.reactions) {
        this.reactions.forEach((emoji) => {
          var img = new Image();
          img.src = "/static/react-icons/" + emoji.icon;
        });
      }
    },
    findUserInCache(id) {
      const user = this.$api.getEntity("user", id);
      if (user) {
        this.$emit("showUserInfo", user);
      }
    },
    async markMessageAsUnread() {
      this.currentWorkspace.read_cursor = this.post.id;
      const query = this.$api.getQuery(`NotificationsQuery`);
      query.data.notifications.filter((n) => {
        if (this.post.id == n.action.object_id) {
          n.read = false;
        }
      });
      await this.$api.mutate({
        mutation: UpdateReadCursorMutation,
        variables: {
          workspace_id: this.currentWorkspace.id,
          read_cursor: this.post.id,
        },
      });
    },
    async markMessageAsRead() {
      this.currentWorkspace.read_cursor = 0;
      const query = this.$api.getQuery(`NotificationsQuery`);
      query.data.notifications.filter((n) => {
        if (this.post.id == n.action.object_id) {
          n.read = true;
        }
      });
      await this.$api.mutate({
        mutation: UpdateReadCursorMutation,
        variables: {
          workspace_id: this.currentWorkspace.id,
          read_cursor: 0,
        },
      });
    },
    async readNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      const notifications = query.data.notifications
        .filter((n) => !n.read)
        .filter((n) => n.action.object_id === this.post.id);
      if (notifications.length) {
        notifications.map((n) => (n.read = true));
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables: {
            notification_ids: notifications.map((n) => n.id),
            read: true,
          },
        });
      }
    },
    async unreadNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => n.read)
          .filter((n) => n.action.object_id === this.post.id);

        if (notifications.length) {
          notifications.map((n) => (n.read = false));
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables: {
              notification_ids: notifications.map((n) => n.id),
              read: false,
            },
          });
        }
      }
    },
  },
};
</script>
