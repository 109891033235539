<template>
  <div
    class="col-grow border-radius5 collaboration-editor"
    id="collaborationPostUploader"
  >
    <div
      class="row flex-1 relative-position q-py-sm"
      style="
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      "
    >
      <add-formate-option
        @toggleBold="toggleBold"
        @toggleItalic="toggleItalic"
        @insertText="insertText"
        :closeHyperlinkModal="closeHyperlinkModal"
        :showAddLink="showAddLink"
        :addLinkTitle="addLinkTitle"
        @openAddLink="openAddLink"
      />
    </div>
    <div class="row flex-1 relative-position">
      <add-media-plus
        @clicked="fileUpload.dialog = true"
        @openSharedFile="sharedFileDialog = true"
        @dropBox="() => dropBox(null, currentUser.id)"
        @googleDrive="() => googleDrive(null, currentUser.id)"
        @fromComputer="uploadFromComputer"
        @meetCall="getVideoCallLink"
        :isMessagingEditor="true"
        :isVideoButtonEnable="false"
      />
      <!-- Editor -->
      <editor
        v-model="content"
        :maxHeight="150"
        ref="messagingEditor"
        class="text-subtitle1"
        :isAbPosition="true"
        :bottomPositions="true"
        :editor-style="{ 'max-height': '136px', 'min-height': '45px' }"
        :mentions="mentionsList"
        @submit="savePost"
        :class-name="['ProseMirror-collaboration-editor']"
        :autofocus="true"
        :placeholder="'Message ' + workspace.title"
        :isMessagingEditor="true"
        @blur="handleBlur"
        @paste="handlePaste"
        :showEditorMenu="false"
        @focus="handleMessageFocus"
        @openAddLink="openAddLink"
      />
      <!-- Send Btn -->
      <q-btn
        no-caps
        dense
        flat
        size="sm"
        padding="0"
        class="video-call-div q-mr-sm"
        style="color: #8e9dad"
        :icon="$icons.fasCheck"
        @click="openTaskListDialog"
      >
        <q-tooltip> Attach task link </q-tooltip>
      </q-btn>
      <q-btn
        no-caps
        dense
        flat
        size="lg"
        padding="0"
        class="video-call-div q-mr-sm"
        style="color: #8e9dad"
        :icon="$icons.matVideoCall"
        @click="getVideoCallLink"
      >
        <q-tooltip anchor="center left" self="center right" :offset="[10, 10]">
          Attach call link
        </q-tooltip>
        <!-- <q-menu
          auto-close
          :offset="[100, 10]"
          content-class="document-help-menu"
        >
          <q-list>
            <q-item
              @click="copyVideoCallLink"
              clickable
              :class="['items-center']"
            >
              <q-icon size="20px" class="q-mr-md" :name="$icons.matLink" />
              Copy call link
            </q-item>
            <q-item
              @click="getVideoCallLink"
              clickable
              :class="['items-center']"
              ><q-icon
                :name="$icons.matVideoCall"
                size="20px"
                class="q-mr-md"
              />
              Attach call to message
            </q-item>
          </q-list>
        </q-menu> -->
      </q-btn>
      <!-- Send Btn -->
      <q-btn
        class="editor-send-btn q-ma-none elevation-0"
        size="10px"
        style="margin: auto; margin-right: 3px"
        :disabled="
          !(
            content ||
            (uploadedFileIds && uploadedFileIds.length > 0) ||
            videoCallLink
          ) ||
          isEnoughCharacter ||
          showProgress ||
          !isFileUploading
        "
        :icon="$icons.fasPaperPlane"
        text-color="white"
        @click="savePost"
      />
      <shared-files-dialog
        v-if="sharedFileDialog"
        v-model="sharedFileDialog"
        @closeDialog="sharedFileDialog = false"
        :attachMediaToPost="addSharedFileToUploader"
        :currentWorkspaceId="currentWorkspace"
      />
      <file-uploader
        v-if="shouldShowDragAndDropMediaUpload"
        v-model="fileUpload.dialog"
        dropzoneid="vabotu-body"
        :uploadArea="false"
        :authToken="authToken"
        :workspace="currentWorkspace"
        :noquery="false"
        :user="currentUser"
        :currentCompanyId="currentCompany.companyId"
        :labels="taskLabels"
        :isWorkspaceOwner="isWorkspaceOwner"
        :imageList="imageList"
        :totalFile="totalFile"
        ref="myFileuploader"
        @done="fileUploadCloseHandler"
        @cancel="fileUpload.dialog = false"
        @showUploader="setUploaderFlag"
        @progress="updateProgress"
        @totalFileCount="(total) => (totalFile = total)"
        @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
      ></file-uploader>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        maximized
        :content-class="['flex-y relative-position dropzone-vabotu-media']"
        v-if="shouldShowDragAndDropMediaUpload"
        v-model="showDragAndDropModal"
      >
        <drag-and-drop-file-modal
          :workspace="workspace"
          :showDragAndDropModal="showDragAndDropModal"
          :closeDialog="closeDragAndDropDialog"
        />
      </q-dialog>
    </div>
    <div style="display: flex; justify-content: center">
      <q-linear-progress
        color="green"
        :indeterminate="query"
        size="20px"
        class="ma-0 progress"
        :value="progress"
        v-if="progress > 0"
        dark
        rounded
      >
        <div class="absolute-full flex label" style="margin-left: 5px">
          <div style="margin-right: 5px">
            <q-btn
              round
              :icon="$icons.matClose"
              size="5px"
              style="color: #000000; opacity: 0.5; background: #ffffff"
              @click="cancelUpload"
            >
              <q-tooltip> Cancel Upload </q-tooltip>
            </q-btn>
          </div>
          <div>
            <p>
              {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile} files uploaded` : 'Uploaded'}}
              <span>{{ Math.round(progress * 100) + "%" }}</span>
            </p>
          </div>
        </div>
      </q-linear-progress>
    </div>
    <div
      style="max-width: 720px"
      class="overflow-auto q-mt-sm media-attachments-wrapper scroll-wrapper"
      v-if="videoCallLink"
    >
      <div class="row relative-position">
        <div class="relative-position q-ml-md" style="width: 220px">
          <q-btn
            round
            :icon="$icons.matLink"
            size="5px"
            style="
              color: #000000;
              opacity: 0.5;
              background: #ffffff;
              position: absolute;
              z-index: 1;
              right: 25px;
              top: 5px;
            "
            @click="$copyTextToClipboard(copyVideoCallLink)"
          >
            <q-tooltip> Copy call link </q-tooltip>
          </q-btn>
          <q-btn
            round
            :icon="$icons.matClose"
            size="5px"
            style="
              color: #000000;
              opacity: 0.5;
              background: #ffffff;
              position: absolute;
              z-index: 1;
              right: 5px;
              top: 5px;
            "
            @click="videoCallLink = false"
          >
            <q-tooltip> Cancel Video Call </q-tooltip>
          </q-btn>

          <img
            style="position: relative; width: 100%"
            class="image"
            src="/static/images/join-call.svg"
          />
        </div>
      </div>
    </div>
    <div
      style="max-width: 720px"
      class="overflow-auto q-mt-sm media-attachments-wrapper scroll-wrapper"
      v-if="totalFiles.length > 0 || folderListArr.length"
    >
      <template v-for="folder in folderList">
        <div v-if="folder" :data-id="folder.id" :key="folder.id">
          <div
            class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm q-mx-md row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
            @click="openFolder(folder)"
          >
            <div class="relative-position" style="height: 40px">
              <q-avatar size="40px" rounded>
                <q-icon
                  class="media-grid-folder-icon"
                  size="40px"
                  :name="$icons.matFolder"
                />
                <div class="media-grid-folder-count">
                  {{ folder.media_count > 9 ? "9+" : folder.media_count }}
                </div>
              </q-avatar>
            </div>
            <div class="file-name" v-tooltip>
              {{ folder.title }}
            </div>
            <div
              @click.stop="removeFolder(folder)"
              class="media-remove cursor-pointer group-hover-media-item"
            >
              <q-icon :name="$icons.matClose" class="close-icon" />
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="totalFiles.length > 0"
        v-sortable="{
          onAdd: onDragAddOrUpdate,
          onUpdate: onDragAddOrUpdate,
          emptyInsertThreshold: 100,
          filter: '.notDraggable',
        }"
      >
        <template v-for="file in totalFiles">
          <div v-if="file" :data-id="file.id" :key="file.id">
            <media
              :showProgress="showProgress"
              :media="file"
              :removeMedia="removeMedia"
              :height="40"
              :fullview="fullview"
              :editDoc="editDoc"
              class="q-mx-md"
            ></media>
          </div>
        </template>
      </div>
    </div>
    <q-circular-progress
      indeterminate
      size="30px"
      :thickness="0.2"
      color="primary"
      class="q-mb-sm q-ml-sm"
      v-if="isLoadingImage"
    />
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="totalFiles"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :hideButtonBack="hideButtonBack"
      :resetDialog="() => resetDialog()"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="clickonmoreoption"
      @clickonhidebutton="ishide = !ishide"
      @deleteCommentPoint="deleteCommentPoint"
      @sendComment="sendComment"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="deleteMediaComment"
      @updateComment="(data) => $emit('updateComment', data)"
      @setActiveMedia="setActiveMedia"
    ></image-viewer-dialog>
    <upgrade-storage-dialog
      v-if="company && company.pricing_version === 3 && upgradeStorageDialog"
      v-model="upgradeStorageDialog"
      :reachedLimitPlan="company && company"
    />
    <task-list-dialog
      v-if="taskList.flag"
      v-model="taskList.flag"
      :closeDialog="closeTaskListDialog"
      @attach="attachTaskLinks"
    />
  </div>
</template>
<script>
import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import throttle from "lodash/throttle";
import getUnixTime from "date-fns/getUnixTime";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import RandomPosition from "random-position";

import { mapGetters } from "vuex";
import Editor from "@/components/Editor/Editor";
import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import AddFormateOption from "@/components/Editor/AddFormateOption";
import FileUploader from "@/components/Miscellaneous/FileUploader";

import Media from "@/components/Miscellaneous/Media";

import {
  SET_WORKSPACE_EDITOR_CONTENT,
  SET_WORKSPACE_EDITOR_MEDIA,
} from "@/store/mutation-types";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import UpgradeStorageDialog from "@/components/Dialogs/UpgradeStorageDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import DragAndDropFileModal from "@/components/Miscellaneous/DragAndDropFileModal.vue";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import UserScope from "@/mixins/UserScope";
import TaskListDialog from "@/components/Dialogs/TaskListDialog";
// import MediaMethodMixin from "@/mixins/MediaMethodMixin";

import { CreatePostMutation, UpdateMediaMutation } from "@/gql";
import { pusher } from "@/pusher";
import mixpanel from "@/mixpanel";

export default {
  name: "CollaborationEditor",
  props: [
    "mentions",
    "workspace",
    "currentUser",
    "taskLabels",
    "isWorkspaceOwner",
    "imageList",
    "readMessageNotifications",
    "shouldShowDragAndDropMediaUpload",
    "scrollToBottom",
    "editDoc",
    "openFolder",
  ],
  mixins: [
    GoogleDriveMethodMixin,
    DropBoxMixin,
    CopiedImageOnEditor,
    FileExtensionMixin,
    UserScope,
    // MediaMethodMixin,
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
    },
    company: {
      cacheKey: "CompanyQuery",
    },
  },
  data() {
    return {
      fileUpload: {
        flag: false,
        dialog: false,
      },
      videoCallLink: false,
      totalFile: 0,
      uploadCount: 0,
      showModal: false,
      dialogMedia: null,
      sharedFileDialog: false,
      upgradeStorageDialog: false,
      pickerApiLoaded: false,
      oauthToken: "",
      gridMediaId: "",
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      ishide: false,
      hideButtonBack: "#231d3b !important",
      dropzone: null,
      requestFiles: {},
      showDragAndDropModal: false,
      progress: 0,
      messageFocused: true,
      taskList: {
        flag: false,
      },
      showAddLink: false,
      addLinkTitle: null,
      addLinkTextPosition: {
        start: null,
        end: null,
      },
    };
  },
  components: {
    Editor,
    FileUploader,
    Media,
    AddMediaPlus,
    AddFormateOption,
    SharedFilesDialog,
    UpgradeStorageDialog,
    ImageViewerDialog,
    DragAndDropFileModal,
    TaskListDialog,
  },
  methods: {
    toggleBold() {
      this.$refs.messagingEditor.$refs.editor.toggleMark("*");
    },
    toggleItalic() {
      this.$refs.messagingEditor.$refs.editor.toggleMark("_");
    },
    insertText(text) {
      if (this.addLinkTextPosition.start && this.addLinkTextPosition.end) {
        this.$refs.messagingEditor.$refs.editor.insertText(
          text,
          false,
          this.$refs.messagingEditor.$refs.editor.value.slice(
            this.addLinkTextPosition.start,
            this.addLinkTextPosition.end
          )
        );
      } else {
        this.$refs.messagingEditor.$refs.editor.insertText(text);
      }
    },
    async copyVideoCallLink() {
      let videoCallLink = `https://${
        process.env.VUE_APP_MEET_URL
      }/${this.workspace.title.replace(/ /g, "-").toLowerCase()}/${
        this.user.id
      }`;

      // if (this.user.id) {
      //   let generateToken = await this.$api.mutate({
      //     mutation: GenerateJwtTokenForUser,
      //   });

      //   if (generateToken.data) {
      //     videoCallLink += "?jwt=" + generateToken.data.generateJwtTokenForUser;
      //   }
      // }

      this.$copyTextToClipboard(videoCallLink);
    },
    attachTaskLinks(data) {
      this.$refs.messagingEditor.$refs.editor.insertText(data);
      // if (this.content) {
      //   this.content = this.content + data;
      // } else {
      //   this.content = data;
      // }
    },
    closeTaskListDialog() {
      this.taskList.flag = false;
    },
    openTaskListDialog() {
      this.taskList.flag = true;
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.sortedFiles];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    closeDragAndDropDialog() {
      this.showDragAndDropModal = false;
    },
    handleBlur() {
      this.messageFocused = false;
    },
    handlePaste() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    handleMessageFocus() {
      this.messageFocused = true;
    },
    openAddLink() {
      this.addLinkTextPosition.start = null;
      this.addLinkTextPosition.end = null;
      if (this.$refs.messagingEditor.$refs.editor.value) {
        const { start, end } =
          this.$refs.messagingEditor.$refs.editor.getSelection();
        this.addLinkTitle =
          this.$refs.messagingEditor.$refs.editor.value.substring(start, end);
        this.addLinkTextPosition.start = start;
        this.addLinkTextPosition.end = end;
      }
      this.showAddLink = true;
    },
    closeHyperlinkModal() {
      this.addLinkTitle = null;
      this.showAddLink = false;
    },
    setUploaderFlag(data) {
      this.showDragAndDropModal = data;
    },
    uploadFromComputer() {
      this.$refs.myFileuploader.openFileDialog();
    },
    getVideoCallLink() {
      this.videoCallLink = !this.videoCallLink;
    },
    async removeMedia(media) {
      this.uploadCount = 0;
      if (media.file_resource !== "outsource") {
        this.deleteCommonMediaHandler(media);
        if (
          media.file_resource === "s3" &&
          media["verifyKey"] !== "fromShared"
        ) {
          this.deleteMediaMutation(media);
        }
        //this.$refs.myFileuploader.removeMedia(media);
      } else {
        this.deleteCommonMediaHandler(media, "collaboration");
      }
      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
    },
    removeFolder(folder) {
      this.$store.dispatch("resetTextEditorFolder", {
        companyId: this.currentCompany.companyId,
        workspaceId: this.currentWorkspace,
        folderId: folder.id,
      });
    },
    async createPostData(outsourceMediaIds = []) {
      if (this.videoCallLink === true) {
        if (this.content == null) {
          this.content = `https://${
            process.env.VUE_APP_MEET_URL
          }/${this.workspace.title.replace(/ /g, "-").toLowerCase()}/${
            this.user.id
          }`;
        } else {
          this.content = `${this.content} \n https://${
            process.env.VUE_APP_MEET_URL
          }/${this.workspace.title.replace(/ /g, "-").toLowerCase()}/${
            this.user.id
          }`;
        }

        this.videoCallLink = false;
      }
      let content = this.content === undefined ? null : this.content;
      // if(content) {
      //   const companyEntity = this.$api.getEntity(
      //     "company",
      //     this.currentCompany.companyId
      //   );

      //   let commentArr = content.split(" ");
      //     let vm = this;
      //   for (let i = 0; i < commentArr.length; i++) {
      //     let item = commentArr[i];
      //     if ((item.startsWith('S') || item.startsWith('T')) && item.includes("-")){
      //       let taskArr =  item.split("-");
      //       const { data } = await vm.$api.query({
      //         query: TaskQuery,
      //         variables: {
      //           id: taskArr[1].replace(/[^a-zA-Z0-9]/g, '').trim(),
      //         },
      //       });
      //       let url;
      //       if(data.task) {
      //         let workspaceName = taskArr[0].substring(1);
      //         let workspace_id = data.task.card ? data.task.card.board.workspace_id : data.task.card_id;
      //         url = `${process.env.VUE_APP_APPBASE_URL}/companies/${companyEntity.uri}/workspaces/${workspace_id}/board?task=${data.task.id}&workspace=${workspace_id}&workspaceName=${workspaceName}`;
      //       }

      //       if(taskArr.length > 2) {
      //         url +="&sub_task="+taskArr[2];
      //       }
      //       commentArr[i] = url;
      //     }
      //   }
      //   content = commentArr.join(" ");
      // }
      // For Any drive Files
      const driveData = this.makeDriveData();

      const media =
        this.sortedFiles && this.sortedFiles.length > 0 ? this.sortedFiles : [];
      const folderIds = map(this.folderList, (f) => f.id);
      if ((content && content.trim() !== "") || media.length > 0) {
        const variables = {
          workspace_id: this.currentWorkspace,
          content: content,
          media: [...this.s3MediaIds, ...outsourceMediaIds], // Media ids
          folder: folderIds, // Folder ids
          post_id: null, // Post id in case of forward post,
          media_urls: driveData,
        };
        const OptID = getUnixTime(new Date());

        const optimisticResponseCreatePost = {
          id: OptID,
          __typename: "post",
          content: variables.content,
          media: media,
          mediafolder: this.folderListArr,
          user_id: this.user.id,
          can_delete: false,
          is_bot: false,
          user: this.user,
          created_at: getUnixTime(new Date()),
          updated_at: getUnixTime(new Date()),
          forwardedPost: null,
          reactionset: [],
          reactiondata: [],
          seens: [],
        };
        this.reset();

        const postsQuery = this.$api.getQuery(
          `MessagesQuery:${this.currentWorkspace}`
        );
        postsQuery.data.messages.unshift(optimisticResponseCreatePost);
        this.readMessageNotifications();
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        const response = await this.$api.mutate({
          mutation: CreatePostMutation,
          variables,
        });

        if (response.data.createPost) {
          postsQuery.data.messages = postsQuery.data.messages.map((post) =>
            post === optimisticResponseCreatePost
              ? response.data.createPost
              : post
          );

          if (response) {
            const ids = response.data.createPost.media
              .filter((a) => a.file_resource !== "s3")
              .map((b) => b.id);
            if (ids && ids.length) {
              this.$eventBus.$emit("getDriveMedia", ids);
            }
          }
          mixpanel.track("Message Create");
        }
        if (response.errors) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: response.errors[0].message,
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
    },
    savePost() {
      if (this.canPostMessageFromWorkspace || this.isWorkspaceOwner) {
        if (!this.isFileUploading) {
          return;
        }
        this.progress = 0;
        this.$refs.myFileuploader.fileList = {};
        if (this.$refs.messagingEditor) {
          this.$refs.messagingEditor.$refs.editor.resetTextArea();
        }
        this.commonOutSourceFileHandler((outsourceMediaIds, code) => {
          if (code === 403) {
            this.upgradeStorageDialog = true;
          } else {
            this.createPostData(outsourceMediaIds);
          }
        });
      } else {
        // this.$q.notify({
        //   color: "negative",
        //   position: "top-right",
        //   message: "Access Denied",
        //   timeout: 2500,
        //   icon: this.$icons.matAnnouncement,
        //   actions: [{ icon: this.$icons.matClose, color: "white" }],
        // });
      }
    },
    reset() {
      this.content = null;
      this.uploadCount = 0;
      this.totalFile = 0;
      this.fileList = {};
      this.copiedFiles = {};
      this.progres = 0;
      this.$store.dispatch("setWorkspaceEditorFolder", {
        value: {},
        companyId: this.currentCompany.companyId,
        workspaceId: this.currentWorkspace,
      });
      this.$eventBus.$emit("clearFilterOnPost");
      this.$eventBus.$emit("messagesScollReset");
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
        fromMessagingEditor: true,
      };
      this.$emit("clickonmoreoption", data);
    },
    deleteCommentPoint(deletedata) {
      const makedata = {
        id: deletedata.id,
        index: deletedata.index,
        media_id: this.gridMediaId,
      };
      this.$emit("deleteCommentPoint", makedata);
    },
    sendComment(data) {
      const commentArg = {
        media_comments_point_id: data.id,
        comment: data.comment,
        media_id: this.gridMediaId,
      };
      this.$emit("sendComment", commentArg);
    },
    deleteMediaComment(commentData) {
      const commentdata = {
        commentid: commentData.commentid,
        index: commentData.index,
        media_id: this.gridMediaId,
        commentPointId: commentData.commentpointid,
      };
      this.$emit("deleteMediaComment", commentdata);
    },
    updateProgress(progress) {
      this.progress = progress / 100;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    cancelUpload() {
      this.$refs.myFileuploader.cancelUpload();
      this.fileList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      setTimeout(() => {
        this.progress = 0;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
      workspaceEditorMedia: "workspaceEditorMedia",
      workspaceEditorFolders: "workspaceEditorFolders",
    }),
    content: {
      get() {
        return this.$store.getters.workspaceEditorContent(
          this.currentCompany.companyId,
          this.currentWorkspace
        );
      },
      set(value) {
        this.$store.commit(SET_WORKSPACE_EDITOR_CONTENT, {
          value,
          companyId: this.currentCompany.companyId,
          workspaceId: this.currentWorkspace,
        });
      },
    },
    fileList: {
      get() {
        const allfiles = this.$store.getters.workspaceEditorMedia(
          this.currentCompany.companyId,
          this.currentWorkspace
        );
        return { ...allfiles, ...this.copiedFiles };
      },
      set(value) {
        this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
          value,
          companyId: this.currentCompany.companyId,
          workspaceId: this.currentWorkspace,
        });
      },
    },
    folderListArr() {
      return this.folderList
        ? uniqBy(
            Object.keys(this.folderList).map((key) => this.folderList[key]),
            "id"
          )
        : [];
    },
    folderList: {
      get() {
        const folders = this.$store.getters.workspaceEditorFolders(
          this.currentCompany.companyId,
          this.currentWorkspace
        );
        return folders;
      },
      set(value) {
        this.$store.dispatch("setWorkspaceEditorFolder", {
          value,
          companyId: this.currentCompany.companyId,
          workspaceId: this.currentWorkspace,
        });
      },
    },
    currentWorkspace() {
      return this.workspace ? this.workspace.id : -1;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    mentionsList() {
      return this.mentions;
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    sortedFiles() {
      return orderBy(
        this.uploadedFileIds.map((id) => this.$api.getEntity("media", id)),
        ["sequence"]
      );
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    // copiedFilesArr() {
    //   return this.uploadedFileIds.filter((id) => {
    //     const media = this.$api.getEntity("media", id);
    //     if (!media) {
    //       console.log("caa;;", media);
    //       return true;
    //     }
    //   });
    //   // .map((id) => this.copiedFiles[id]);
    // },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          media.file_resource === "google" ||
          media.file_resource === "dropbox"
        ) {
          return media;
        }
      });
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              media.file_resource !== "google" &&
              media.file_resource !== "dropbox"
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    counter() {
      if (this.content) {
        return this.content.toString().length;
      } else {
        return 0;
      }
    },
    isEnoughCharacter() {
      return this.counter > 20000;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = filter(this.fileList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = filter(this.fileList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    isFileUploading() {
      if (this.progress === 0 || this.progress === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeDestroy() {
    if (this.dropzone) {
      this.dropzone.destroy();
    }
  },
  watch: {
    ishide: {
      handler: function (newValue) {
        if (newValue) {
          this.hideButtonBack = "#8E9DAD !important";
        } else {
          this.hideButtonBack = "#231d3b !important";
        }
      },
      deep: true,
    },
    content: throttle(function (to) {
      if (to === "") return;

      const { companyId } = this.currentCompany;
      const workspaceId = this.currentWorkspace;

      const channel = pusher.channel(
        `private-company=${companyId}-workspace=${workspaceId}`
      );
      if (channel) {
        channel.trigger("client-typing", {
          userId: this.user.id,
        });
      }
    }, 1000),
  },
};
</script>
