<script>
import { MarkPostsAsSeenMutation } from "@/gql";
import { pusher } from "@/pusher";

export default {
  name: "SeenIndicators",
  props: ["posts", "userId", "workspaceId", "companyId"],
  data() {
    return {
      focused: false,
    };
  },
  render() {
    return null;
  },
  beforeMount() {
    this.focused = true;
  },
  mounted() {
    this.markMessagesAsSeen();
  },
  created() {
    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
    this.subscribe(this.workspaceId);
  },
  beforeDestroy() {
    this.unsubscribe(this.workspaceId);
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
  },
  methods: {
    async markMessagesAsSeen() {
      if (!this.focused) return;
      const unseenByUser = (post) =>
        post.seens.every((seen) => seen.user.id !== this.userId);
      // console.log(this.posts);
      const unseenPostIds = this.posts
        .filter((post) => post.user.id !== this.userId)
        .filter(unseenByUser)
        .map((post) => post.id);
      if (unseenPostIds.length) {
        await this.$api.mutate({
          mutation: MarkPostsAsSeenMutation,
          variables: {
            ids: unseenPostIds,
          },
        });
        const channel = pusher.channel(
          `private-company=${this.companyId}-workspace=${this.workspaceId}`
        );
        channel.trigger("client-seen", {
          userId: this.userId,
          ids: unseenPostIds,
          companyId: this.companyId,
          seenAt: Math.floor(new Date().getTime() / 1000),
        });
      }
    },
    subscribe(workspaceId) {
      const channel = pusher.channel(
        `private-company=${this.companyId}-workspace=${workspaceId}`
      );

      if (!channel) {
        setTimeout(() => this.subscribe(workspaceId), 1000);
        return;
      }

      channel.bind("client-seen", this.handleSeens);
    },
    unsubscribe(workspaceId) {
      const channel = pusher.channel(
        `private-company=${this.companyId}-workspace=${workspaceId}`
      );

      channel.unbind("client-seen", this.handleSeens);
    },
    handleSeens(data) {
      // this.markMessagesAsSeen();
      if (data.userId === this.userId) return;

      data.ids.forEach((id) => {
        const post = this.$api.getEntity("post", id);

        const seen = post.seens.find((seen) => seen.user.id === data.userId);
        const user = this.$api.getEntity("user", data.userId);
        if (!seen) {
          post.seens.push({
            user,
            seen_at: data.seenAt,
          });
        }
      });
    },
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
    },
  },
  watch: {
    posts() {
      this.markMessagesAsSeen();
    },
    focused() {
      this.markMessagesAsSeen();
    },
    workspaceId(to) {
      this.subscribe(to);
      this.markMessagesAsSeen();
    },
  },
};
</script>
