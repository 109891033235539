<template>
  <div class="row flex-wrap items-center q-mx-xs" v-if="typingUserIds.length">
    <template>
      <q-avatar
        v-for="(typingUserId, index) in typingUserIds"
        :key="typingUserId"
        :class="[
          index < typingUserIds.length - 1 ? 'task-member-position' : '',
          'cursor-pointer',
          'q-my-sm',
        ]"
        color="blue-grey-2"
        size="20px"
      >
        <q-tooltip>
          {{ membersById[typingUserId].first | capitalize }}
          {{ membersById[typingUserId].last | capitalize }}
        </q-tooltip>
        <img
          :src="membersById[typingUserId].pic"
          :alt="membersById[typingUserId].first"
          v-if="membersById[typingUserId].pic"
          @click.stop
        />
        <!-- <span class="text-avatar-style" @click.stop v-else>
          {{ membersById[typingUserId].first | firstChar
          }}{{ membersById[typingUserId].last | firstChar }}
        </span> -->
        <avatar v-else :size="20" :customStyle="{'font-size':'9px', 'font-weight':'600'}" :username="membersById[typingUserId].first+' '+membersById[typingUserId].last"></avatar>
      </q-avatar>
    </template>
    <!--  -->
    <div id="wave" v-if="typingUserIds.length">
      <span class="dot greybtn"></span>
      <span class="dot greybtn"></span>
      <span class="dot greybtn"></span>
    </div>
  </div>
</template>
<script>
import keyBy from "lodash/keyBy";
import { MembersQuery } from "@/gql";
import { pusher } from "@/pusher";

import Avatar from 'vue-avatar'

export default {
  name: "TypingIndicators",
  props: ["companyId", "workspaceId", "userId"],
  api: {
    members: {
      query: MembersQuery,
      cacheKey: "MembersQuery",
    },
  },
  data() {
    return {
      typingUsers: {},
    };
  },
  components:{
    Avatar,
  },
  created() {
    this.typingTimeouts = {};
    this.subscribe(this.workspaceId);
  },
  beforeDestroy() {
    this.unsubscribe(this.workspaceId);
  },
  methods: {
    handleTyping(data) {
      if (data.userId === this.userId) return;

      this.$set(this.typingUsers, data.userId, true);

      clearTimeout(this.typingTimeouts[data.userId]);

      this.typingTimeouts[data.userId] = setTimeout(() => {
        this.$delete(this.typingUsers, data.userId);
      }, 1500);
    },
    subscribe(workspaceId) {
      const channel = pusher.channel(
        `private-company=${this.companyId}-workspace=${workspaceId}`
      );

      if (!channel) {
        setTimeout(() => this.subscribe(workspaceId), 1000);
        return;
      }

      channel.bind("client-typing", this.handleTyping);
    },
    unsubscribe(workspaceId) {
      const channel = pusher.channel(
        `private-company=${this.companyId}-workspace=${workspaceId}`
      );

      channel.unbind("client-typing", this.handleSeens);
    },
  },
  computed: {
    membersById() {
      return keyBy(this.members, "id");
    },
    typingUserIds() {
      return Object.keys(this.typingUsers);
    },
  },
  watch: {
    workspaceId(to) {
      this.subscribe(to);
    },
  },
};
</script>
<style lang="stylus" scoped>
div#wave {
  position: relative;
  text-align: center;
  width: 25px;
  height: 25px;

  .dot {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 3px;
    background: #303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
</style>
