<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-py-md bg-white attach-task-to-message overflow-hidden">
      <div class="close-btn-bg q-px-lg">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        />
      </div>
      <div class="row title q-px-lg">
        <span class="q-mx-auto">Task</span>
      </div>
      <div class="row q-my-sm q-px-lg">
        <div class="">
          <q-input
            v-model="searchTask"
            outlined
            dense
            clearable
            placeholder="Search Tasks"
            autofocus
          >
            <template v-slot:prepend>
              <q-icon :name="$icons.matSearch" />
            </template>
          </q-input>
        </div>
        <div class="flex-1 q-ml-sm">
          <div class="">
            <q-btn
              outline
              size="md"
              class="dropdown-set full-width"
              no-caps
              style="font-weight:400"
              :icon-right="$icons.matKeyboardArrowDown"
            >
              <span class="text-bold q-mr-xs">Workspace: </span
              ><span>{{ selectedWorkspace && selectedWorkspace.title }} </span>
              <q-menu content-class="workspace-menu" fit>
                <div class="search-member" style="padding:0px">
                  <q-input
                    autofocus
                    ref="search"
                    v-model="search"
                    outlined
                    placeholder="Filter workspaces"
                    clearable
                    dense
                  >
                    <template v-slot:prepend>
                      <q-icon :name="$icons.matSearch" />
                    </template>
                  </q-input>
                </div>
                <q-list
                  style="min-width:140px; max-height: 250px; overflow: auto"
                >
                  <q-item
                    @click="chooseWorkspace(workspace)"
                    v-for="workspace in filteredWorkspaces"
                    :key="workspace.id"
                    clickable
                    v-close-popup
                  >
                    <q-item-section>
                      {{ workspace.title }}
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="row items-center justify-center task-list-height"
      >
        <q-circular-progress
          indeterminate
          :thickness="0.1"
          size="75px"
          color="primary"
          class="q-ma-md"
        />
      </div>
      <!-- List Of tasks -->
      <div
        v-else
        class="column flex-no-wrap overflow-auto q-px-lg task-list-height"
      >
        <template v-for="(card, index) in cardList">
          <div
            class="q-mb-md full-width"
            :index="index"
            :key="card.id"
            v-if="cardTasks[card.id]"
          >
            <div class="row board-cardtitle-ce text-h6" style="color:#172b4d">
              {{ card.title }}
            </div>
            <template v-for="(task, index) in cardTasks[card.id]">
              <div
                :class="[
                  index === 0 ? 'q-mt-sm q-mb-md' : 'q-my-md',
                  'row items-center task-item',
                ]"
                :key="'task-' + task.id"
              >
                <span
                  @click="selectTask(task.id)"
                  class="attacht-task-to-message-checkbox"
                >
                  <div
                    v-if="selectedTaskIds.includes(task.id)"
                    class="selected-custom-selection-checkbox"
                  ></div>
                </span>
                <div class="task-title row itmes-center">
                  <div class="task-ticket-number">
                    {{
                      selectedWorkspace.title.substring(0, 2).toUpperCase()
                    }}-{{ task.id }}
                  </div>
                  <div
                    class="text-truncate q-ml-sm"
                    v-tooltip
                    style="line-height:unset"
                  >
                    {{ task.title }}
                    <q-tooltip v-if="isTruncated">
                      {{ task.title }}
                    </q-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div class="text-center">
        <q-btn
          flat
          dense
          label="Cancel"
          color="text-black"
          size="md"
          no-caps
          style="width:100px"
          class="q-mr-md"
          @click="closeDialog"
        />
        <q-btn
          dense
          no-caps
          :disable="!selectedTaskIds.length"
          color="primary"
          size="md"
          label="Attach"
          padding="5px 5px"
          style="width:100px"
          @click="attachTasksLinks"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import Fuse from "fuse.js";

import { TasksQuery } from "@/gql";
export default {
  name: "TaskListDialog",
  props: ["value", "task", "closeDialog", "isFromMyworks", "userId"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      searchTask: null,
      selectedWorkspace: null,
      cardList: null,
      isLoading: false,
      search: null,
      isTruncated: false,
      selectedTaskIds: [],
      workspaceTasks: [],
    };
  },
  mounted() {
    this.selectedWorkspace = this.currentWorkspace;
    this.cardList = this.currentWorkspace.boards[0].cards;
  },
  methods: {
    //Call when attach btn click
    attachTasksLinks() {
      let taskLinks = [];
      //Generate links for each selected task
      this.selectedTaskIds.forEach((taskId) => {
        taskLinks.push(this.generateTaskLink(taskId));
      });
      this.$emit("attach", taskLinks.join(","));
      this.closeDialog();
    },

    generateTaskLink(taskId) {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.selectedWorkspace.id}/board?task=${taskId}&workspace=${this.selectedWorkspace.id}&workspaceName=${this.selectedWorkspace.title}`;
      return " " + taskLink + " ";
    },
    selectTask(taskId) {
      if (this.selectedTaskIds.includes(taskId)) {
        this.selectedTaskIds = this.selectedTaskIds.filter(
          (id) => id !== taskId
        );
      } else {
        this.selectedTaskIds.push(taskId);
      }
    },
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.cardList = workspace.boards[0].cards;
      this.getTasks(workspace.id);
    },
    async getTasks(workspaceId) {
      this.workspaceTasks = [];

      if (
        !this.selectedWorkspace.boards[0].tasks ||
        this.selectedWorkspace.boards[0].tasks.length === 0
      ) {
        const query = this.$api.getQuery(
          `TasksQuery:${this.selectedWorkspace.id}`
        );
        if (query.data && query.data.tasks) {
          this.workspaceTasks = query.data.tasks;
        } else {
          this.isLoading = true;
        }
        const response = await this.$api.query({
          query: TasksQuery,
          variables: {
            workspace_id: workspaceId,
          },
          cacheKey() {
            return `TasksQuery:${this.selectedWorkspace.id}`;
          },
        });
        this.isLoading = false;
        if (response.data) {
          this.workspaceTasks = response.data.tasks;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
    }),
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    tasks() {
      if (this.selectedWorkspace.boards[0].tasks) {
        return this.selectedWorkspace.boards[0].tasks;
      } else {
        return this.workspaceTasks;
      }
    },
    cardTasks: {
      get() {
        return groupBy(orderBy(this.filteredTasks, ["sequence"]), "card_id");
      },
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    filterTaskFuse() {
      if (this.tasks.length) {
        return new Fuse(this.tasks, {
          keys: ["title", "id"],
          shouldSort: true,
          minMatchCharLength: 3,
          threshold: 0.0,
        });
      }
      return [];
    },
    filteredTasks() {
      return this.searchTask
        ? this.filterTaskFuse.search(this.searchTask)
        : this.filterTaskFuse.hasOwnProperty("list")
        ? this.filterTaskFuse.list
        : this.filterTaskFuse;
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
          minMatchCharLength: 3,
          threshold: 0.0,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 25px 0px 25px;
}
</style>
