<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white task-duplication">
      <div class="close-btn-bg">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto">Move message</span>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        Workspace
      </div>
      <div class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedWorkspace && selectedWorkspace.title"
        >
          <q-menu content-class="workspace-menu" auto-close>
            <div class="search-member">
              <q-input
                autofocus
                ref="search"
                v-model="search"
                outlined
                placeholder="Filter workspaces"
                clearable
                dense
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
              </q-input>
            </div>
            <q-list style="min-width:140px; max-height: 250px; overflow: auto">
              <q-item
                @click="chooseWorkspace(workspace)"
                v-for="workspace in filteredWorkspaces"
                :key="workspace.id"
                clickable
              >
                <q-item-section>
                  {{ workspace.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          padding="5px 5px"
          @click="closeDialog"
        />
        <!-- <q-btn
          dense
          no-caps
          padding="5px 5px"
          class="create-task-btn"
          color="primary"
          size="md"
          label="Move message"
          @click="createMessageHandler"
          :loading="isLoading"
        /> -->
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import orderBy from "lodash/orderBy";

import { CreatePostMutation, DeletePostMutation } from "@/gql";
import Fuse from "fuse.js";

export default {
  name: "MoveMessageDialog",
  props: ["value", "message", "closeDialog"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      selectedWorkspace: null,
      isLoading: false,
      search: null,
    };
  },
  mounted() {
    this.selectedWorkspace =
      this.filteredWorkspaces && this.filteredWorkspaces[0];
  },
  methods: {
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
    },
    createMessageHandler() {
      this.isLoading = true;
      const message = {
        workspace_id: parseInt(this.selectedWorkspace.id),
        content: this.message.content,
        media: this.message.media ? this.message.media.map((m) => m.id) : [],
        folder: this.message.mediafolder
          ? this.message.mediafolder.map((m) => m.id)
          : [],
        media_urls: this.message.media_urls ? this.message.media_urls : [],
        user_id: this.user.id,
      };
      this.addMessage(message);
    },
    async addMessage(variables) {
      // const variables = {
      //   message,
      // };

      await this.$api
        .mutate({
          mutation: CreatePostMutation,
          variables,
        })
        .then(async (response) => {
          // 1. get query
          const postsQuery = this.$api.getQuery(
            `MessagesQuery:${this.selectedWorkspace.id}`
          );
          if (postsQuery.data) {
            postsQuery.data.messages.unshift(response.data.createPost);
          }

          const variables = {
            id: this.message.id,
          };

          const query = this.$api.getQuery(
            `MessagesQuery:${this.currentWorkspaceId}`
          );
          query.data.messages = query.data.messages.filter(
            (post) => post.id !== this.message.id
          );

          await this.$api.mutate({
            mutation: DeletePostMutation,
            variables,
          });

          this.isLoading = false;
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Message moved successfully.",
            icon: this.$icons.matAnnouncement,
          });
          this.closeDialog();
        });
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    workspaceIds() {
      return this.workspaces.map((m) => {
        if (this.currentWorkspaceId != m.id) {
          return m.id;
        }
      });
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 25px 0px 25px;
}
</style>
