<template>
  <q-card class="column no-wrap reply-message-card">
    <q-card-section class="row col-shrink no-wrap q-pa-none">
      <!-- Left Section -->
      <div
        class="column col-grow left-section relative-position q-pa-lg flex-no-wrap"
      >
        <div class="dialog-title">
          Share message
        </div>
        <div class="q-mt-sm">
          <q-btn
            outline
            size="md"
            class="dropdown-set full-width"
            no-caps
            style="font-weight:400"
            :icon-right="$icons.matKeyboardArrowDown"
            :label="
              `Workspace: ${selectedWorkspace && selectedWorkspace.title}`
            "
          >
            <q-menu content-class="workspace-menu" fit auto-close>
              <div class="search-member">
                <q-input
                  autofocus
                  ref="search"
                  v-model="search"
                  outlined
                  placeholder="Filter workspaces"
                  clearable
                  dense
                >
                  <template v-slot:prepend>
                    <q-icon :name="$icons.matSearch" />
                  </template>
                </q-input>
              </div>
              <q-list
                style="min-width:140px; max-height: 250px; overflow: auto"
              >
                <q-item
                  @click="chooseWorkspace(workspace)"
                  v-for="workspace in filteredWorkspaces"
                  :key="workspace.id"
                  clickable
                >
                  <q-item-section>
                    {{ workspace.title }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <div class="row edit-editor overflow-auto q-mt-md q-pa-md">
          <add-media-plus
            @clicked="fileUpload.dialog = true"
            @openSharedFile="sharedFileDialog = true"
            @dropBox="() => dropBox(null, currentUser.id)"
            @googleDrive="() => googleDrive(null, currentUser.id)"
            @fromComputer="fromComputer"
          />
          <!-- Editor -->
          <editor
            v-model="content"
            :isAbPosition="true"
            :mentions="mentionsList"
            :maxHeight="541"
            :minHeight="400"
            :class-name="['ProseMirror-collaboration-editor']"
            :autofocus="true"
            :showMenu="false"
            placeholder="Type a comment here"
            @submit="savePost"
          />
          <shared-files-dialog
            v-model="sharedFileDialog"
            :imageList="imageList"
            @closeDialog="sharedFileDialog = false"
            :attachMediaToPost="addSharedFileToUploader"
            :currentWorkspaceId="currentWorkspaceId"
          />
          <vue-dropzone-file-upload
            hidden
            ref="vueFileUploader"
            :workspace="currentWorkspaceId"
            :isWorkspaceOwner="isWorkspaceOwner"
            :authToken="authToken"
            :totalFile="totalFile"
            @close="fileUploadCloseHandler"
            @setProgress="setProgress"
            @totalFileCount="(total) => (totalFile = total)"
            @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
          ></vue-dropzone-file-upload>
        </div>
        <div class="q-mt-md dialog-height">
          <div class="row flex-no-wrap items-center">
            <div
              v-if="hasNotification"
              class="cursor-pointer notification-sidebar-dot q-mr-sm"
            ></div>
            <div class="messaging-user-detail-chip">
              <span
                class="user-name cursor-pointer"
                style="font-weight:bold"
                @click="$emit('showUserInfo', repliedMessage.user)"
                >{{ repliedMessage.user.first }}
                {{ repliedMessage.user.last }}</span
              >
              <span class="time">
                <span
                  v-if="repliedMessage.updated_at > repliedMessage.created_at"
                >
                  {{ repliedMessage.created_at | gettime }}
                  <span class="capitalize-text an-12">(Edited)</span>
                </span>
                <span v-else>{{ repliedMessage.created_at | gettime }}</span>
              </span>
            </div>
          </div>
          <div
            v-html="messageContent"
            class="post-content dont-break-out editor-prototype"
            style="white-space:normal"
          ></div>
          <div
            v-if="
              repliedMessage.reactiondata &&
                Object.keys(repliedMessage.reactiondata).length
            "
            class="q-pt-sm"
          >
            <div class="row inline">
              <template
                v-for="(reactiondata, index) in repliedMessage.reactiondata"
              >
                <div class="q-mr-sm" :key="index">
                  <div class="relative-position reaction-icon	row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                    />
                    <div class="text-black" style="font-size:12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height:30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center ">
                            <div class="q-mr-sm">
                              <q-avatar
                                size="26px"
                                color="blue-grey-11"
                                text-color="grey-10"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Section -->
      <div
        class="column col-grow right-section justify-between relative-position q-py-lg flex-no-wrap"
      >
        <div>
          <div class="attachments-title">
            Attachments
          </div>
          <q-btn
            :icon="$icons.matClose"
            text-color="black"
            class="close-icon"
            round
            flat
            padding="0"
            dense
            @click="cancel"
          />
          <div class="q-mt-xs" v-if="progress > 0">
            <q-linear-progress
              color="green"
              :indeterminate="query"
              size="20px"
              class="ma-0 progress"
              :value="progress"
              dark
              rounded
            >
              <div class="absolute-full flex label" style="margin-left: 5px">
                <div style="margin-right: 5px">
                  <q-btn
                    round
                    :icon="$icons.matClose"
                    size="5px"
                    style="color: #000000; opacity: 0.5; background: #ffffff"
                  >
                    <q-tooltip>
                      Cancel Upload
                    </q-tooltip>
                  </q-btn>
                </div>
                <div>
                  <p>
                    {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile} files uploaded` : 'Uploaded'}}
                    <span>{{ Math.round(progress * 100) + "%" }}</span>
                  </p>
                </div>
              </div>
            </q-linear-progress>
          </div>
          <template v-if="totalFiles.length > 0 || folderListArr.length">
            <div class="q-mt-sm comment-media-label">
              Attached Media:
            </div>
            <div
              class="overflow-auto q-mt-sm full-width media-attachments-wrapper scroll-wrapper comment-editor-box"
              v-if="totalFiles.length > 0 || folderListArr.length > 0"
            >
              <template v-for="folder in folderListArr">
                <div v-if="folder" :data-id="folder.id" :key="folder.id">
                  <div
                    class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm  row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
                    style="max-width:230px"
                    @click="openFolder(folder)"
                  >
                    <div class="relative-position" style="height:40px">
                      <q-avatar size="40px" rounded>
                        <q-icon
                          class="media-grid-folder-icon"
                          size="40px"
                          :name="$icons.matFolder"
                        />
                        <div class="media-grid-folder-count">
                          {{
                            folder.media_count > 9 ? "9+" : folder.media_count
                          }}
                        </div>
                      </q-avatar>
                    </div>
                    <div class="file-name" v-tooltip>
                      {{ folder.title }}
                    </div>
                    <div
                      @click.stop="removeFolder(folder)"
                      class="media-remove cursor-pointer group-hover-media-item"
                    >
                      <q-icon :name="$icons.matClose" class="close-icon" />
                    </div>
                  </div>
                </div>
              </template>
              <div
                v-if="totalFiles.length > 0"
                v-sortable="{
                  onAdd: onDragAddOrUpdate,
                  onUpdate: onDragAddOrUpdate,
                  emptyInsertThreshold: 100,
                }"
              >
                <template v-for="file in totalFiles">
                  <div v-if="file" :data-id="file.id" :key="file.id">
                    <media
                      :showProgress="showProgress"
                      :media="file"
                      :removeMedia="removeMedia"
                      :height="40"
                      :maxWidth="230"
                      :fullview="fullview"
                      :editDoc="editDoc"
                      id="comment-editor-media"
                    ></media>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-if="messageMedia.length || messageMediaFolders.length">
            <div class="q-mt-sm comment-media-label">
              Message Media:
            </div>
            <div
              class="overflow-auto q-mt-sm full-width media-attachments-wrapper scroll-wrapper comment-editor-box"
            >
              <template v-for="folder in messageMediaFolders">
                <div v-if="folder" :key="folder.id">
                  <div
                    class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm  row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
                    style="max-width:230px"
                    @click="openFolder(folder)"
                  >
                    <div class="relative-position" style="height:40px">
                      <q-avatar size="40px" rounded>
                        <q-icon
                          class="media-grid-folder-icon"
                          size="40px"
                          :name="$icons.matFolder"
                        />
                        <div class="media-grid-folder-count">
                          {{
                            folder.media_count > 9 ? "9+" : folder.media_count
                          }}
                        </div>
                      </q-avatar>
                    </div>
                    <div class="file-name" v-tooltip>
                      {{ folder.title }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-for="file in messageMedia">
                <div v-if="file" :data-id="file.id" :key="file.id">
                  <media
                    :showProgress="showProgress"
                    :media="file"
                    :removeMedia="removeMedia"
                    :height="40"
                    :maxWidth="230"
                    :fullview="fullview"
                    :isReplyCommentMedia="true"
                    :editDoc="editDoc"
                    id="comment-editor-media"
                  ></media>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div style="align-self: center;">
          <q-btn
            text-color="black"
            dense
            padding="7px 32px"
            label="Cancel"
            class="q-mr-sm"
            outline
            no-caps
            @click="cancel"
          />
          <q-btn
            text-color="white"
            color="primary"
            dense
            padding="7px 38px"
            label="Send"
            @click="savePost"
            :disabled="
              !content || isEnoughCharacter || showProgress || !isFileUploading
            "
            no-caps
          />
        </div>
      </div>
    </q-card-section>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      :fromUploader="false"
      :imageList="[...totalFiles, ...messageMedia]"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :resetDialog="resetDialog"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @sendComment="(data) => $emit('sendComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
    ></image-viewer-dialog>
  </q-card>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import keys from "lodash/keys";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";
import RandomPosition from "random-position";
import getUnixTime from "date-fns/getUnixTime";

import DropBoxMixin from "@/mixins/DropBoxMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import UserScope from "@/mixins/UserScope";

import { compile } from "@/components/Editor/MarkupUtils";

import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import Editor from "@/components/Editor/Editor";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import Media from "@/components/Miscellaneous/Media";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import { UpdateMediaMutation, CreatePostMutation } from "@/gql";
// import mixpanel from "@/mixpanel";
import Fuse from "fuse.js";
import Avatar from 'vue-avatar';

export default {
  name: "ReplyMessageDialog",
  props: [
    "repliedMessage",
    "mentionsList",
    "workspace",
    "isWorkspaceOwner",
    "imageList",
    "currentUser",
    "taskLabels",
    "readMessageNotifications",
    "openFolder",
    "editDoc",
    "scrollToBottom",
  ],
  mixins: [
    GoogleDriveMethodMixin,
    DropBoxMixin,
    CopiedImageOnEditor,
    UserScope,
  ],
  components: {
    AddMediaPlus,
    Editor,
    SharedFilesDialog,
    VueDropzoneFileUpload,
    Media,
    ImageViewerDialog,
    Avatar,
  },
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      content: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      previousTagged: [],
      fileList: null,
      sharedFileDialog: false,
      progress: 0,
      showModal: false,
      dialogMedia: null,
      gridMediaId: "",
      ishide: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      totalFile: 0,
      uploadCount: 0,
      folderList: null,
      selectedWorkspace: null,
      search: null,
    };
  },
  mounted() {
    this.selectedWorkspace = this.currentWorkspace;
  },
  methods: {
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
    },
    removeFolder(folder) {
      this.deleteFolderHandler(folder);
    },
    cancel() {
      this.$emit("closeDialog");
      if (this.uploadedMedia.length) {
        this.uploadedMedia.forEach((media) => {
          this.removeMedia(media);
        });
      }
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.sortedFiles];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    async savePost() {
      if (this.canPostMessageFromWorkspace || this.isWorkspaceOwner) {
        if (this.content && this.content.trim() !== "") {
          this.commonOutSourceFileHandler((outsourceMediaIds) => {
            this.createSharedPost(outsourceMediaIds);
          });
        }
      } else {
        // this.$q.notify({
        //   color: "negative",
        //   position: "top-right",
        //   message: "Access Denied",
        //   timeout: 2500,
        //   icon: this.$icons.matAnnouncement,
        //   actions: [{ icon: this.$icons.matClose, color: "white" }],
        // });
      }
    },
    async createSharedPost(outsourceMediaIds = []) {
      const content = this.content;

      let media = [];
      // For Any drive Files
      const driveData = this.makeDriveData();

      this.$emit("closeDialog");

      if (content && content.trim() !== "") {
        media =
          this.sortedFiles && this.sortedFiles.length > 0
            ? this.sortedFiles
            : [];
        const folderIds = map(this.folderList, (f) => f.id);
        const variables = {
          workspace_id: this.selectedWorkspace.id,
          content: content,
          media: [...this.s3MediaIds, ...outsourceMediaIds], // Media ids
          post_id: this.repliedMessage.id, // Post id in case of forward post,
          media_urls: driveData,
          folder: folderIds,
        };

        const OptID = getUnixTime(new Date());
        const oldPost = {
          id: this.repliedMessage.id,
          workspace_id: this.currentWorkspaceId,
          media: this.repliedMessage.media,
          user_id: this.repliedMessage.user.id,
          created_at: this.repliedMessage.created_at,
          owner: this.repliedMessage.user,
          content: this.repliedMessage.content,
          mediafolder: this.repliedMessage.mediafolder,
          __typename: "post",
        };

        const fakePost = {
          id: OptID,
          __typename: "post",
          content: variables.content,
          media: media,
          mediafolder: this.folderListArr,
          user_id: this.currentUser.id,
          can_delete: false,
          is_bot: false,
          // unread: false, // NEEDTOREMOVE
          user: this.currentUser,
          created_at: getUnixTime(new Date()),
          updated_at: getUnixTime(new Date()),
          forwardedPost: oldPost,
          reactionset: [],
          reactiondata: [],
          seens: [],
        };
        this.reset();
        this.readMessageNotifications();
        // 1. get query
        const postsQuery = this.$api.getQuery(
          `MessagesQuery:${this.selectedWorkspace.id}`
        );
        if (postsQuery.data) {
          // 2. add fake entity
          postsQuery.data.messages.unshift(fakePost);
          this.scrollToBottom();
          // 3. get real entity
          const response = await this.$api.mutate({
            mutation: CreatePostMutation,
            variables,
          });
          // 4. replace fake entity
          postsQuery.data.messages = postsQuery.data.messages.map((task) =>
            task === fakePost ? response.data.createPost : task
          );
        } else {
          await this.$api.mutate({
            mutation: CreatePostMutation,
            variables,
          });
        }

        // mixpanel.track("Message Create", { quote: true });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Message shared successfully.",
          icon: this.$icons.matAnnouncement,
        });
        this.$mixpanelEvent("message-reply", {});
      }
    },
    reset() {
      this.content = null;
      this.$eventBus.$emit("clearFilterOnPost");
      this.$eventBus.$emit("messagesScollReset");
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    cancelUpload() {
      this.$refs.vueFileUploader.cancelUpload();
      this.fileList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      setTimeout(() => {
        this.progress = 0;
      }, 10);
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    async removeMedia(media) {
      this.uploadCount = 0;
      this.deleteCommonMediaHandler(media);
      if (media.is_document_section == 1 || (media.file_resource === "s3" && media["verifyKey"] !== "fromShared")) {
        this.deleteMediaMutation(media);
      }
      this.$refs.vueFileUploader.removeMedia(media);
      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    // currentWorkspace() {
    //   return this.workspace ? this.workspace.id : -1;
    // },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              media.file_resource !== "google" &&
              media.file_resource !== "dropbox"
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    uploadedMedia() {
      return this.uploadedFileIds.map((id) => this.$api.getEntity("media", id));
    },
    messageMedia() {
      return this.repliedMessage.media;
    },
    messageMediaFolders() {
      return this.repliedMessage.mediafolder
        ? this.repliedMessage.mediafolder
        : [];
    },
    folderListArr() {
      return this.folderList
        ? uniqBy(
            Object.keys(this.folderList).map((key) => this.folderList[key]),
            "id"
          )
        : [];
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          media.file_resource === "google" ||
          media.file_resource === "dropbox"
        ) {
          return media;
        }
      });
    },
    sortedFiles() {
      return orderBy(this.uploadedMedia, ["sequence"]);
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = filter(
        [...this.totalFiles, ...this.messageMedia],
        (o) => {
          return o.id === vm.gridMediaId;
        }
      );
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = filter(
        [...this.totalFiles, ...this.messageMedia],
        (o) => {
          return o.id === vm.gridMediaId;
        }
      );
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    counter() {
      if (this.content) {
        return this.content.toString().length;
      } else {
        return 0;
      }
    },
    isEnoughCharacter() {
      return this.counter > 20000;
    },
    isFileUploading() {
      if (this.progress === 0 || this.progress === 1) {
        return true;
      } else {
        return false;
      }
    },
    messageContent() {
      return compile(this.repliedMessage.content);
    },
    hasNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.object_id === this.repliedMessage.id);
        return notifications.length > 0;
      }
      return false;
    },
  },
};
</script>
<style>
.dialog-height {
  max-height: 200px;
  overflow: auto;
}
.workspace-menu {
  padding: 10px;
}
</style>
