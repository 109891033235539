<template>
  <div class="flex-y flex-1 bg-white vabotu-body">
    <div class="flex-1 flex-x">
      <div class="flex-1 bg-blue-grey-1 q-mt-xl q-mx-md position-relative">
        <div
          class="upload-area dz-clickable bg-white position-relative"
          ref="uploadArea"
        >
          <div style="font-size: 90px">Upload</div>
          <span style="font-size: 40px">to {{ workspace.title }}</span>
        </div>
      </div>
    </div>
    <div class="close-btn cursor-pointer" @click="closeDialog">
      <q-icon :name="$icons.matClose" class="text-black" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DragAndDropFileModal",
  data() {
    return {
      proxyModel: false,
    };
  },
  props: ["workspace", "showDragAndDropModal", "closeDialog"],
};
</script>

<style scoped>
.upload-area {
  opacity: 1;
}
.upload-area div {
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.upload-area span {
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.close-btn {
  position: absolute;
  top: 0;
  font-size: 75px;
  right: 27px;
}
</style>
