/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div class="flex-1 border-radius-left overflow-auto position-relative">
    <q-btn
      :class="showJumpToTop ? 'jump-to-top jump-to-top-visible' : 'jump-to-top'"
      rounded
      no-caps
      @click="scrollToTop"
    >
      <q-icon :name="$icons.matKeyboardArrowDown" size="md" outline />
    </q-btn>
    <div class="column flex-1 collaboration-thead-section q-mr-xs full-height">
      <q-toolbar
        class="post-filter-toolbar elevation-0 q-mb-xs q-mt-sm"
        v-if="messageFilter.flag"
      >
        <q-toolbar-title v-if="messageFilter.type === FILTER.MEMBER">
          <span class="an-15 regular-text">Filtered by member: </span>
          <span class="an-15 text-bold">{{ messageFilter.data }}</span>
        </q-toolbar-title>
        <q-toolbar-title v-else>
          <span class="an-15 regular-text">Filtered by date: </span>
          <span class="an-15 text-bold">
            {{ messageFilter.data.fromDate }} to
            {{ messageFilter.data.toDate }}
          </span>
        </q-toolbar-title>
        <q-btn
          @click="clearFilter"
          round
          flat
          style="color: #000000; background-color: #ffffff;"
          :icon="$icons.matClose"
          class="q-ml-xs q-pt-none"
          size="8px"
        />
      </q-toolbar>
      <!-- Put the code of ScrollTo TOP  -->
      <div class="text-center">
        <q-circular-progress
          indeterminate
          size="30px"
          :thickness="0.2"
          color="primary"
          v-if="pagination.loading"
        />
      </div>
      <!-- Scroll start only from this -->
      <div class="column flex-no-wrap justify-end full-height flex-1">
        <div
          v-if="hasPosts"
          class="overflow-auto hide-expansion-arrow hide-expansion-hover"
          ref="scrollContainer"
          id="scroll-container"
          @scroll="handleThreadsScroll"
        >
          <q-expansion-item
            v-for="(group, i) in postGroup"
            :key="i"
            default-opened
            ref="expansionItems"
            exact-active-class="transparent"
            header-class="text-uppercase	text-subtitle1 bg-white text-bold text-grey-10 items-center justify-center q-pb-none"
            header-style="position:sticky;top:0;z-index: 100;min-height:24px"
          >
            <template v-slot:header>
              <q-separator style="background:#DBE1E7;" class="q-mr-sm" />
              <div
                :class="[
                  i === todaytask ? 'today-task' : '',
                  i === yesterdayTask ? 'yesterday-task' : '',
                ]"
              >
                {{ i | dateConversion }}
              </div>
              <q-separator style="background:#DBE1E7;" class="q-ml-sm" />
            </template>
            <!-- Transparent Card -->
            <div class="flex-1">
              <template v-for="(post, index) in group">
                <thread-message
                  v-if="post"
                  :key="index"
                  :className="[group.length - 1 === index ? '' : 'q-mb-lg']"
                  :post="post"
                  :isAdmin="isAdmin"
                  :currentWorkspace="userWorkspace"
                  :currentCompany="currentCompany"
                  :activeCompany="activeCompany"
                  :labels="labels"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :currentUser="currentUser"
                  :canRemoveMessageFromWorkspace="canRemoveMessageFromWorkspace"
                  :canPostMessageFromWorkspace="canPostMessageFromWorkspace"
                  :reactions="reactions"
                  :createTaskFromMessage="createTaskFromMessageHandler"
                  :taskMessageLoader="taskMessageLoader"
                  :selectedMessageId="selectedMessageId"
                  @showUserInfo="showUserInfo"
                  @sharePost="sharePost"
                  @editPost="editPost"
                  @reactOnPost="reactOnMessage"
                  @deletePostDialogHandler="deletePostDialogHandler"
                  @movePostDialogHandler="movePostDialogHandler"
                >
                  <template v-if="post.forwardedPost" slot="forwardedmedia">
                    <post-media
                      v-if="
                        (post.forwardedPost.media &&
                          post.forwardedPost.media.length) ||
                          (post.forwardedPost.mediafolder &&
                            post.forwardedPost.mediafolder.length)
                      "
                      :post="post.forwardedPost"
                      :openFolder="openFolder"
                      :deleteFolderHandler="deleteFolderHandler"
                      :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                      :attachments="reorderMedia(post.forwardedPost.media)"
                      :canRemoveMedia="
                        canRemoveMessageFromWorkspace ||
                          isWorkspaceOwner ||
                          (currentUser && currentUser.id === post.user.id)
                      "
                      :forceDisableRemove="true"
                      :removeMedia="removeMedia"
                      :postId="post.id"
                      :labels="labels"
                      :user="user"
                      :workspace="currentWorkspace"
                      :company="currentCompany"
                      :isWorkspaceOwner="isWorkspaceOwner"
                      :fromComputer="fromComputer"
                      :editDoc="editDoc"
                      @clickonmoreoption="clickonmoreoption"
                      @deleteCommentPoint="deleteCommentPoint"
                      @sendComment="sendComment"
                      @updatepositions="updatepositions"
                      @updateMediaCommentPointLabel="
                        updateMediaCommentPointLabel
                      "
                      @deleteMediaComment="deleteMediaComment"
                      @updateComment="updateComment"
                      @closeMediaModel="
                        (data) => $emit('closeMediaModel', data)
                      "
                      :class="{
                        'mt-2':
                          post.forwardedPost.content === null ||
                          post.forwardedPost.content === '',
                      }"
                    />
                  </template>
                  <post-media
                    v-if="
                      post.media.length ||
                        (post.mediafolder && post.mediafolder.length)
                    "
                    :attachments="post.media"
                    :post="post"
                    :openFolder="openFolder"
                    :deleteFolderHandler="deleteFolderHandler"
                    :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                    :canRemoveMedia="
                      canRemoveMessageFromWorkspace ||
                        isWorkspaceOwner ||
                        (currentUser && currentUser.id === post.user.id)
                    "
                    :forceDisableRemove="
                      post.content === '' && post.media.length <= 1
                    "
                    :removeMedia="removeMedia"
                    :postId="post.id"
                    :labels="labels"
                    :user="user"
                    :workspace="currentWorkspace"
                    :isWorkspaceOwner="isWorkspaceOwner"
                    :fromComputer="fromComputer"
                    :editDoc="editDoc"
                    @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                    @clickonmoreoption="clickonmoreoption"
                    @deleteCommentPoint="deleteCommentPoint"
                    @sendComment="sendComment"
                    @updatepositions="updatepositions"
                    @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
                    @deleteMediaComment="deleteMediaComment"
                    @updateComment="updateComment"
                  />
                </thread-message>
              </template>
            </div>
          </q-expansion-item>
        </div>
        <div v-if="deletePostDialog.flag">
          <confirm-dialog
            v-if="deletePostDialog.flag"
            v-model="deletePostDialog.flag"
            title="Delete Message?"
            question="Are you sure you want to delete this message?"
            cancleText="Cancel"
            successText="Delete"
            :cancleMethod="resetdeletePostDialog"
            :successMethod="deletePost"
            :loading="deletePostLoader"
          />
        </div>
        <div v-if="delete_media.flag">
          <confirm-dialog
            v-if="delete_media.flag"
            v-model="delete_media.flag"
            title="Remove File?"
            question="Are you sure you want to detach the media from the post?"
            cancleText="Cancel"
            successText="Delete"
            :cancleMethod="() => removeMediaDialogHandler(false)"
            :successMethod="() => removeMediaDialogHandler(true)"
          />
        </div>
        <q-dialog
          transition-hide="none"
          transition-show="none"
          persistent
          v-if="editpostDialog"
          v-model="editpostDialog"
          content-class="edit-message-dialog"
        >
          <edit-message-dialog
            :editMessageObj="postData"
            :mentionsList="taggUsers"
            :taskLabels="labels"
            :workspace="userWorkspace"
            :currentUser="currentUser"
            :isWorkspaceOwner="isWorkspaceOwner"
            :imageList="imageList"
            :editMessageLoader="editMessageLoader"
            :openFolder="openFolder"
            :editDoc="editDoc"
            @closeDialog="
              $emit('showDragAndDropMediaUpload');
              editpostDialog = false;
            "
            @updatePost="updatePostHandler"
            @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
            @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
            @updatepositions="(data) => $emit('updatepositions', data)"
            @updateMediaCommentPointLabel="
              (data) => $emit('updateMediaCommentPointLabel', data)
            "
            @updateComment="(data) => $emit('updateComment', data)"
            @sendComment="(data) => $emit('sendComment', data)"
            @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
            @closeMediaModel="(data) => $emit('closeMediaModel', data)"
          />
        </q-dialog>
        <q-dialog
          transition-hide="none"
          transition-show="none"
          persistent
          v-if="sharepostDialog"
          v-model="sharepostDialog"
          content-class="reply-message-dialog"
        >
          <reply-message-dialog
            :repliedMessage="sharedata"
            :currentCompany="currentCompany"
            :mentionsList="taggUsers"
            :workspace="userWorkspace"
            :taskLabels="labels"
            :currentUser="currentUser"
            :isWorkspaceOwner="isWorkspaceOwner"
            :imageList="imageList"
            :readMessageNotifications="readMessageNotifications"
            :openFolder="openFolder"
            :editDoc="editDoc"
            :scrollToBottom="scrollToBottom"
            @showUserInfo="showUserInfo"
            @closeDialog="
              $emit('showDragAndDropMediaUpload');
              sharepostDialog = false;
            "
            @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
            @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
            @updatepositions="(data) => $emit('updatepositions', data)"
            @updateMediaCommentPointLabel="
              (data) => $emit('updateMediaCommentPointLabel', data)
            "
            @updateComment="(data) => $emit('updateComment', data)"
            @sendComment="(data) => $emit('sendComment', data)"
            @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
            @closeMediaModel="(data) => $emit('closeMediaModel', data)"
          />
        </q-dialog>
        <profile-dialog
          v-model="profileDialog.show"
          v-if="profileDialog.show"
          :recepient="userProfileDialog"
          :user="currentUser"
          :isWorkspaceOwner="isWorkspaceOwner"
          :currentWorkspaceId="currentWorkspace"
          :currentCompany="currentCompany"
          @close="clearUserInfoDialog"
        />
        <move-message-dialog
          v-if="movePostDialog.flag"
          v-model="movePostDialog.flag"
          :message="movePostDialog.message"
          :closeDialog="closeMoveMessageDialog"
        />
      </div>
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="currentCompany.accessToken"
      @close="fileUploadCloseHandler"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="currentWorkspace"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Remove Folder?"
      question="Are you sure you want to detach this folder from the post?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import fromUnixTime from "date-fns/fromUnixTime";
import getUnixTime from "date-fns/getUnixTime";

import get from "lodash/get";
// import set from "lodash/set";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";

import UserScope from "@/mixins/UserScope";

import PostMedia from "@/components/Miscellaneous/PostMedia";
import ProfileDialog from "@/components/ProfileDialog";
import ThreadMessage from "@/views/MessagingView/Threads/ThreadMessage";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload.vue";
import EditMessageDialog from "@/views/MessagingView/Threads/EditMessageDialog";
import ReplyMessageDialog from "@/views/MessagingView/Threads/ReplyMessageDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";
import mixpanel from "@/mixpanel";
import MoveMessageDialog from "@/components/Dialogs/MoveMessageDialog.vue";

import {
  MessagesQuery,
  DeletePostMutation,
  UpdatePostMutation,
  DetachPostMediaMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
  CopyMediaFolderMutation,
  CopyMediaMutation,
  DetachMessageFolderMutation,
  //TaskQuery,
} from "@/gql";

export default {
  name: "Threads",
  mixins: [UserScope],
  props: [
    "labels",
    "workspaces",
    "imageList",
    "readMessageNotifications",
    "posts",
    "workspaceMembers",
    "messageSearch",
    "drawerRight",
    "scrollToBottom",
    "createTaskFromMessage",
    "editDoc",
    "openFolder",
    "folderList",
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    privateTasks: {
      cacheKey() {
        return `PrivateTasksQuery:${this.userWorkspace.boards[0].id}`;
      },
      defaultValue: null,
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.userWorkspace.boards[0].id}`;
      },
    },
  },
  components: {
    PostMedia,
    ProfileDialog,
    ThreadMessage,
    ConfirmDialog,
    VueDropzoneFileUpload,
    EditMessageDialog,
    ReplyMessageDialog,
    PostCopyMoveFileFolder,
    MoveMessageDialog,
  },
  beforeCreate() {
    this.$eventBus.$off("filter_message");
    this.$eventBus.$off("getInfoEvent");
    this.$eventBus.$off("clearFilterOnPost");
    this.$eventBus.$off("messagesScollReset");
    this.$eventBus.$off("toggle_collapse");
  },
  beforeDestroy() {
    this.$eventBus.$off("filter_message");
    document.removeEventListener("scroll", this.handlingScroll);
    this.$eventBus.$off("clearFilterOnPost", this.clearFilter);
    this.$eventBus.$off("messagesScollReset");
    this.$eventBus.$off("toggle_collapse");
  },
  created() {
    this.$eventBus.$on("filter_message", this.filterHandler);
    this.$eventBus.$on("getInfoEvent", this.getInfoEvent);
    this.$eventBus.$on("toggle_collapse", this.toggleAll);
    document.removeEventListener("scroll", this.handlingScroll);
    this.$eventBus.$on("messagesScollReset", this.scrollToTop);
  },
  mounted() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
    document
      .getElementsByClassName("collaboration-thead-section")[0]
      .addEventListener("scroll", this.handlingScroll);
  },
  filters: {
    dateConversion(postGroupDate) {
      if (postGroupDate === format(new Date(), "iiii, MMM. do")) {
        return "Today";
      } else if (
        postGroupDate === format(subDays(new Date(), 1), "iiii, MMM. do")
      ) {
        return "Yesterday";
      }
      return postGroupDate;
    },
  },
  data() {
    return {
      deletePostLoader: false,
      deletePostDialog: {
        flag: false,
        id: null,
        loader: false,
      },
      movePostDialog: {
        flag: false,
        message: null,
        loader: false,
      },
      opened: true,
      messageFilter: {
        collapse: true,
        flag: false,
        type: null,
        data: null,
      },
      FILTER: {
        DATE: "date",
        MEMBER: "member",
      },
      filterData: {
        userId: null,
        fromDate: null,
        toDate: null,
        content: null,
      },
      editpostDialog: false,
      sharepostDialog: false,
      sharepostdata: null,
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      profileDialog: {
        show: false,
        user: null,
      },
      pagination: {
        loading: false,
        limit: 10,
        offset: 0,
      },
      noPosts: false,
      upArrowDisplay: "none",
      showJumpToTop: false,
      upBottomSide: "15px",
      post: {
        id: null,
        content: null,
        html: null,
        media: null,
        mediaFolders: null,
      },
      taskMessageLoader: false,
      isPrivateTask: true,
      selectedMessageId: null,
      shouldDeleteTask: true,
      mediaId: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      editMessageLoader: false,
      copyMoveDialog: {
        data: [],
        post: null,
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      deleteFolder: {
        id: null,
        post: null,
        flag: false,
        loader: false,
      },
      isScrollbottom: true,
    };
  },
  methods: {
    async deleteFolderHandler(folderId, post) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.post = post;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            folder: [this.deleteFolder.id],
            post_id: this.deleteFolder.post.id,
          };
          await this.$api.mutate({
            mutation: DetachMessageFolderMutation,
            variables,
          });
          //Remove from Entity cache
          const postEntity = this.$api.getEntity(
            "post",
            this.deleteFolder.post.id
          );
          postEntity.mediafolder = postEntity.mediafolder.filter(
            (f) => f.id !== this.deleteFolder.id
          );
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.post = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    copyFileFolderHandler(workspace, folder) {
      if (this.copyMoveDialog.isFolder) {
        this.copyFolders(workspace);
      } else {
        this.copyFiles(workspace, folder);
      }
    },
    async copyFolders(workspace) {
      try {
        this.copyMoveDialog.loading = true;
        // Array of folders
        const folderIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFiles(workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when copy to folder
          if (workspace.id === this.currentWorkspace) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspace}`
            );
            query.data.mediaFolders = query.data.mediaFolders.map((f) => {
              if (f.id === folder.id) {
                f.media_count += fileIds.length;
              }
              return f;
            });
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    copyMoveFileFolderHandler(
      items,
      isFolder = false,
      isRoot = true,
      post = null
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.post = post;
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.post = null;
    },
    handleThreadsScroll(event) {
      let scrollHeight = document.querySelector("#scroll-container")
        .scrollHeight;
      let totalHeight =
        scrollHeight > 1250
          ? scrollHeight - scrollHeight * 0.6
          : scrollHeight > 1100
          ? scrollHeight - scrollHeight * 0.7
          : scrollHeight > 920 && scrollHeight - scrollHeight * 0.8;
      if (event.target.scrollTop < totalHeight) {
        this.showJumpToTop = true;
      } else {
        this.showJumpToTop = false;
      }
      if (this.isScrollbottom && event.target.scrollTop < 5) {
        this.pagination.loading = true;
        this.fetchScrollHandler();
      } else {
        this.pagination.loading = false;
        //this.showJumpToTop = false;
      }
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.vueFileUploader.openFileDialog();
    },
    fileUploadCloseHandler(fileList) {
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
      }
    },
    reorderMedia(media) {
      return orderBy(uniqBy(media, "id"), ["sequence"]);
    },
    async createTaskFromMessageHandler(
      task,
      postMedia,
      messageId,
      mentionArray
    ) {
      const fileteredUsers = [this.user, ...this.workspaceMembers].filter(
        (m) => {
          return mentionArray.includes(m.username);
        }
      );
      this.createTaskFromMessage({
        task,
        postMedia,
        messageId,
        fileteredUsers,
      });
    },
    toggleAll() {
      this.$refs.expansionItems.forEach((ref) => {
        this.opened ? ref.hide() : ref.show();
      });
      this.opened = !this.opened;
    },
    scrollToTop() {
      if (this.$refs.scrollContainer) {
        this.$refs.scrollContainer.scrollTop = document.querySelector(
          "#scroll-container"
        ).scrollHeight;
      }
      this.showJumpToTop = false;
    },
    getInfoEvent(rightSidebar) {
      if (!rightSidebar) {
        this.upBottomSide = "15px";
      } else {
        this.upBottomSide = "15px";
      }
    },
    handlingScroll() {
      // const scrolltop = document.getElementsByClassName(
      //   "collaboration-thead-section"
      // )[0].scrollTop;
      // if (scrolltop === 1 || scrolltop === 0) {
      //   this.upArrowDisplay = "none";
      // } else {
      //   this.upArrowDisplay = "block";
      // }
    },
    goToTopCollaboration() {
      document.getElementsByClassName(
        "collaboration-thead-section"
      )[0].scrollTop = 1;
    },
    clickonmoreoption(item) {
      this.$emit("clickonmoreoption", item);
    },
    deleteCommentPoint(deletedata) {
      this.$emit("deleteCommentPoint", deletedata);
    },
    sendComment(data) {
      this.$emit("sendComment", data);
    },
    updatepositions(updateposition) {
      const positions = {
        id: updateposition.id,
        x: updateposition.x,
        y: updateposition.y,
        media_id: this.gridMediaId,
      };
      this.$emit("updatepositions", positions);
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    deleteMediaComment(commentData) {
      this.$emit("deleteMediaComment", commentData);
    },
    updateComment(changedata) {
      this.$emit("updateComment", changedata);
    },
    hasClass(elem, className) {
      return elem.className.split(" ").indexOf(className) > -1;
    },
    async fetchScrollHandler() {
      if (this.pagination.loading) {
        this.pagination.offset = this.posts.length;

        const variables = {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          workspace_id: this.currentWorkspace,
          filter_user_id: this.filterData.userId,
          filter_date_to: this.filterData.toDate,
          filter_date_from: this.filterData.fromDate,
          content: this.filterData.content,
        };

        const newPosts = await this.$api.query({
          query: MessagesQuery,
          variables,
        });

        const currentPosts = this.$api.getQuery(
          `MessagesQuery:${this.currentWorkspace}`
        );

        currentPosts.data.messages = [
          ...currentPosts.data.messages,
          ...newPosts.data.messages,
        ];

        this.pagination.loading = false;
        if (!newPosts.data.messages.length) {
          this.isScrollbottom = false;
          document.querySelector("#scroll-container").scrollTop = 0;
        } else {
          document.querySelector("#scroll-container").scrollTop = 100;
        }
      }
    },
    resetPost() {
      this.post = {
        id: null,
        content: null,
        html: null,
        media: null,
        mediaFolders: null,
      };
    },
    resetPagination() {
      this.pagination.offset = 0;
      this.pagination.limit = 10;
    },
    filterHandler(filter) {
      this.scrollToTop();
      this.resetPagination();

      this.filterData = {
        userId: null,
        toDate: null,
        fromDate: null,
        content: null,
      };

      if (filter.type === this.FILTER.DATE) {
        this.filterByDate(filter.variables);
      } else if (filter.type === this.FILTER.MEMBER) {
        this.filterByMember(filter.variables);
      }
    },
    filterByMember(member) {
      this.messageFilter = {
        type: this.FILTER.MEMBER,
        data: member.name,
        flag: true,
      };
      this.filterData.userId = member.id;
      this.filterPost();
    },
    filterByDate(dates) {
      this.filterData.fromDate = dates.from;
      this.filterData.toDate = dates.to;
      this.filterPost();
      this.messageFilter = {
        type: this.FILTER.DATE,
        data: {
          fromDate: dates.from,
          toDate: dates.to,
        },
        flag: true,
      };
    },
    async clearFilter() {
      this.scrollToTop();
      this.resetPagination();

      this.filterData = {
        userId: null,
        toDate: null,
        fromDate: null,
        content: null,
      };
      this.filterPost();

      this.messageFilter = {
        type: null,
        flag: false,
        data: null,
      };

      this.$eventBus.$emit("clearFilter");
    },
    async searchMessageHandler(data) {
      this.scrollToTop();
      this.resetPagination();
      this.messageFilter = {
        type: null,
        flag: false,
        data: null,
      };
      this.filterData = {
        userId: null,
        toDate: null,
        fromDate: null,
        content: data.variables,
      };
      this.$eventBus.$emit("clearFilter");
      this.filterPost();
    },
    async filterPost() {
      this.noMessageHandler(false);
      const variables = {
        // offset: 0,
        workspace_id: this.currentWorkspace,
        filter_user_id: this.filterData.userId,
        filter_date_to: this.filterData.toDate,
        filter_date_from: this.filterData.fromDate,
        content: this.filterData.content,
      };

      const newPosts = await this.$api.query({
        query: MessagesQuery,
        variables,
      });

      const currentPosts = this.$api.getQuery(
        `MessagesQuery:${this.currentWorkspace}`
      );
      currentPosts.data.messages = [...newPosts.data.messages];

      if (this.posts && this.posts.length <= 0) this.noMessageHandler();
    },
    async updatePostHandler(data) {
      await this.updatePost(data);
      this.editpostDialog = false;
      this.$emit("showDragAndDropMediaUpload");
      this.resetPost();
    },

    async updatePost(post) {
      this.editMessageLoader = true;
      const variables = {
        id: post.id,
        content: post.html,
        media: post.media,
        media_urls: post.media_urls,
        workspace_id: this.currentWorkspace,
        folder: post.mediaFolders,
      };
      const hasId = this.posts.find((p) => p.id === post.id);

      if (hasId) {
        const result = await this.$api.mutate({
          mutation: UpdatePostMutation,
          variables,
        });
        this.editMessageLoader = false;
        if (result) {
          const ids = result.data.updatePost.media
            .filter((a) => a.file_resource !== "s3")
            .map((b) => b.id);

          mixpanel.track("Message Update");
          this.$eventBus.$emit("getDriveMedia", ids);
        }
      }
    },
    deletePostDialogHandler(id) {
      this.deletePostDialog = {
        flag: true,
        id,
      };
    },
    movePostDialogHandler(message) {
      this.movePostDialog = {
        flag: true,
        message,
      };
    },
    closeMoveMessageDialog() {
      this.movePostDialog = {
        flag: false,
        message: null,
      };
    },
    async deletePost() {
      this.deletePostLoader = true;
      const variables = {
        id: this.deletePostDialog.id,
      };

      const query = this.$api.getQuery(
        `MessagesQuery:${this.currentWorkspace}`
      );
      query.data.messages = query.data.messages.filter(
        (post) => post.id !== this.deletePostDialog.id
      );

      const PinMessagesQ = this.$api.getQuery(
        `PinMessagesQuery:${this.currentWorkspace}`
      );

      if (PinMessagesQ.data.pinMessages) {
        PinMessagesQ.data.pinMessages = PinMessagesQ.data.pinMessages.filter(
          (pinMessages) => pinMessages.id !== this.deletePostDialog.id
        );
      }

      await this.$api.mutate({
        mutation: DeletePostMutation,
        variables,
      });
      mixpanel.track("Message Delete");
      this.deletePostLoader = false;
      this.resetdeletePostDialog();
    },
    resetdeletePostDialog() {
      this.deletePostDialog = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    async editPost(post) {
      this.post = {
        html: post.content,
        id: post.id,
        media: post.media,
        mediaFolders: post.mediafolder,
      };
      this.editpostDialog = !this.editpostDialog;
      //this.$emit("hideDragAndDropMediaUpload");
    },
    async sharePost(post) {
      this.sharepostdata = post;
      this.sharepostDialog = !this.sharepostDialog;
      this.$emit("hideDragAndDropMediaUpload");
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        const variables = {
          post_id: this.delete_media.post,
          media: [this.delete_media.id],
        };

        await this.$api.mutate({
          mutation: DetachPostMediaMutation,
          variables,
        });
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    noMessageHandler(visibility = true) {
      if (visibility) {
        setTimeout(() => {
          this.noPosts = true;
        }, 3000);
      } else {
        this.noPosts = false;
      }
    },
    async reactOnMessage(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }

      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      const newReactiondata = { ...post.reactiondata };
      this.scrollToBottom();
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          this.readMessageNotifications();
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          });
          this.readMessageNotifications();
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
          mixpanel.track("Message Reaction", { name: reaction.name });
        }
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = {
                ...newReactiondata,
              };
            }
          });
        }
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          },
        ];
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = {
                ...newReactiondata,
              };
            }
          });
        }
        this.readMessageNotifications();
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Message Reaction", { name: reaction.name });
      }
      this.scrollToTop();
    },
    async deleteMediaFromPost(variables) {
      await this.$api.mutate({
        mutation: DetachPostMediaMutation,
        variables,
      });
    },
    callback: debounce(function() {
      this.filterPost();
    }, 300),
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    userProfileDialog() {
      return this.profileDialog.user;
    },
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else if (this.generalWorkspace) {
        return this.generalWorkspace.id;
      } else {
        return 1;
      }
    },
    currentUser() {
      if (this.user) {
        return this.user;
      }
      return null;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    accessToken() {
      if (this.currentCompany) {
        return this.currentCompany.accessToken;
      } else {
        return null;
      }
    },
    taggUsers() {
      if (this.workspaceMembers) {
        return this.workspaceMembers;
      } else {
        return null;
      }
    },
    postGroup() {
      if (this.posts) {
        const postList = this.posts.map((post) => {
          return {
            ...post,
          };
        });
        // const uniqueList = uniqWith(postList, isEqual)
        const uniqueList = orderBy(
          uniqWith(postList, isEqual),
          ["id"],
          ["asc"]
        );
        return groupBy(uniqueList, (post) => {
          return format(fromUnixTime(post["created_at"]), "iiii, MMM. do");
        });
      } else {
        return null;
      }
    },
    userWorkspace() {
      return this.workspaces.find(
        (w) => w.id === Number(this.currentWorkspace)
      );
    },
    isWorkspaceOwner() {
      if (this.userWorkspace && this.user) {
        return this.user.id === this.userWorkspace.owner.id;
      }
      return false;
    },
    generalWorkspace() {
      return this.workspaces.find((w) => w.id === 1 && w.is_general === 1);
    },
    postData() {
      return this.post;
    },
    sharedata() {
      return this.sharepostdata;
    },
    todaytask() {
      return format(new Date(), "iiii, MMM. do");
    },
    yesterdayTask() {
      return format(subDays(new Date(), 1), "iiii, MMM. do");
    },
    hasPosts() {
      return !isEmpty(this.postGroup);
    },
    isAdmin() {
      return this.user && this.user.roles.find((o) => o.name === "admin");
    },
    cardTasks: {
      get() {
        return orderBy(this.privateTasks, ["sequence"]);
      },
    },
    cardList() {
      return this.userWorkspace.boards[0].cards;
    },
  },
  watch: {
    "$route.params.workspace": function() {
      this.clearFilter();
      this.resetPagination();
    },
    // editpostDialog(e) {
    //   this.closeModal("editpostDialog", e);
    // },
    // sharepostDialog(e) {
    //   this.closeModal("sharepostDialog", e);
    // },
    "messageSearch.searchText"(value) {
      this.scrollToTop();
      this.resetPagination();
      this.messageFilter = {
        type: null,
        flag: false,
        data: null,
      };
      this.filterData = {
        userId: null,
        toDate: null,
        fromDate: null,
        content: value,
      };
      this.$eventBus.$emit("clearFilter");
      this.callback();
    },
  },
};
</script>
